import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Glitch from 'glitch-javascript-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faEnvelope,
    faStar,
    faChevronDown,
    faChevronUp,
    faGamepad,
    faVideo,
    faDesktop,
    faGlobe,
    faInfoCircle,
    faImages,
} from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';
import { faAndroid, faAppStore, faSteam } from '@fortawesome/free-brands-svg-icons';
import GallerySection from '../../component/tools/gallery';

// IMPORTANT: from 'react-helmet-async' rather than 'react-helmet'
import { Helmet } from 'react-helmet-async';

const FALLBACK_IMAGE = 'https://www.glitch.fun/assets/images/titles/no_image_2.png';



// Utility to strip HTML tags for meta descriptions
const stripHtml = (html) => {
    if (!html) return '';

    // Use browser-specific DOMParser if available
    if (typeof window !== 'undefined' && typeof DOMParser !== 'undefined') {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    // Use a fallback for Node.js
    // Strip HTML tags using a regular expression
    return html.replace(/<\/?[^>]+(>|$)/g, '');
};

const MediakitStandalonePage = ({ mediakitServerData }) => {
    /**
     * If we have server data, we use it immediately
     * so meta tags are correct on first SSR.
     * If it's missing (user navigated client side),
     * we can do a fallback fetch in useEffect.
     */


    const { id } = useParams();
    const [mediakit, setMediakit] = useState(mediakitServerData || null);
    const [isFetching, setIsFetching] = useState(!mediakitServerData);
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(true);
    const [videoEmbedUrl, setVideoEmbedUrl] = useState(null);
    const [mediaFiles, setMediaFiles] = useState([]);

    useEffect(() => {
        // If we didn't get data from server, do a client fetch as fallback.
        if (!mediakit) {
            const fetchData = async () => {
                try {
                    setIsFetching(true);
                    const response = await Glitch.api.Titles.view(id, { include: 'media' });
                    const mediakitData = response.data.data;
                    setMediakit(mediakitData);
                    handleMediaSetup(mediakitData);
                } catch (error) {
                    console.error('Error fetching mediakit:', error);
                } finally {
                    setIsFetching(false);
                }
            };
            fetchData();
        } else {
            // We already have mediakit from server, just handle media URLs
            handleMediaSetup(mediakit);
        }
        // eslint-disable-next-line
    }, [id]);

    // Helper to set up videoEmbedUrl and mediaFiles
    const handleMediaSetup = (mediakitData) => {
        if (!mediakitData) return;
        let videoSet = false;
        if (mediakitData.video_url) {
            let embedUrl = null;
            if (
                mediakitData.video_url.includes('youtube.com') ||
                mediakitData.video_url.includes('youtu.be')
            ) {
                const videoId =
                    mediakitData.video_url.split('v=')[1] ||
                    mediakitData.video_url.split('/').pop();
                embedUrl = `https://www.youtube.com/embed/${videoId}`;
            } else if (mediakitData.video_url.includes('vimeo.com')) {
                const videoId = mediakitData.video_url.split('/').pop();
                embedUrl = `https://player.vimeo.com/video/${videoId}`;
            }
            setVideoEmbedUrl(embedUrl);
            videoSet = true;
        }
        const media = mediakitData.media || [];
        let remainingMedia = [];
        let firstVideoMedia = null;

        if (!videoSet) {
            for (let i = 0; i < media.length; i++) {
                const mediaItem = media[i];
                if (
                    !firstVideoMedia &&
                    mediaItem.mime_type &&
                    mediaItem.mime_type.startsWith('video/')
                ) {
                    firstVideoMedia = mediaItem;
                    continue;
                } else {
                    remainingMedia.push(mediaItem);
                }
            }
        } else {
            remainingMedia = media;
        }
        setMediaFiles(remainingMedia);

        if (!videoSet && firstVideoMedia) {
            setVideoEmbedUrl(firstVideoMedia.url);
        }
    };

    const toggleAdditionalInfo = () => {
        setShowAdditionalInfo(!showAdditionalInfo);
    };

    // If no data at all (server or client), show a message
    if (!mediakit && !isFetching) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <h2>Media Kit not found.</h2>
            </div>
        );
    }

    // If we are still client-fetching
    if (!mediakit && isFetching) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <h2>Loading Media Kit...</h2>
            </div>
        );
    }

    // Now we have mediakit data
    // Prepare store/demo links
    const storeLinks = [];
    if (mediakit?.demo_url) {
        storeLinks.push({
            url: mediakit.demo_url,
            label: 'Download Demo',
            icon: faDownload,
        });
    }
    if (mediakit?.website_url) {
        storeLinks.push({
            url: mediakit.website_url,
            label: 'Visit Website',
            icon: faDesktop,
        });
    }
    if (mediakit?.steam_url) {
        storeLinks.push({
            url: mediakit.steam_url,
            label: 'Steam Store',
            icon: faSteam,
        });
    }
    if (mediakit?.itch_url) {
        storeLinks.push({
            url: mediakit.itch_url,
            label: 'Itch.io Store',
            icon: faGlobe,
        });
    }
    if (mediakit?.apple_url) {
        storeLinks.push({
            url: mediakit.apple_url,
            label: 'Apple App Store',
            icon: faAppStore,
        });
    }
    if (mediakit?.android_url) {
        storeLinks.push({
            url: mediakit.android_url,
            label: 'Google Play Store',
            icon: faAndroid,
        });
    }

    const showGameInformation =
        mediakit?.developer ||
        mediakit?.publisher ||
        mediakit?.release_date ||
        mediakit?.age_rating ||
        mediakit?.multiplayer_options ||
        mediakit?.pricing ||
        mediakit?.availability ||
        mediakit?.platform_compatibility;

    // Prepare SEO metadata
    const pageTitle = (mediakit?.name || mediakitServerData?.name)
        ? `MediaKit: ${mediakit?.name || mediakitServerData?.name}`
        : 'Mediakit';
    const pageDescription = stripHtml(
        mediakit?.short_description || mediakitServerData?.short_description || 'View this game’s media kit!'
    );
    const pageImage = mediakit?.image_main || mediakitServerData?.image_main || FALLBACK_IMAGE;

    // Because we're in SSR + client, we can't rely on window in SSR
    // but if you do want og:url to reflect the current URL, see server logic
    const pageUrl = (
        typeof window !== 'undefined'
            ? window.location.href
            : `https://www.glitch.fun/community/mediakits/standalone/${mediakit?.id}`
    );

    return (
        <>
            {/**
             * Helmet tags for SSR / pre-rendering
             */}
            <Helmet>
                {/* HTML Title */}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph */}
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Glitch Gaming" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />
            </Helmet>

            <Fragment>
                {/* Fullscreen Banner */}
                {mediakit?.image_banner && (
                    <div
                        style={{
                            aspectRatio: '3840 / 1240', // or use a ratio-box approach with padding-bottom
                            background: `url(${mediakit.image_banner}) center center / cover no-repeat`,
                            position: 'relative',
                            color: 'white',
                        }}
                    >
                        {/* Overlay */}
                        <div
                            className="overlay"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }}
                        />

                        {/* Hero content absolutely positioned */}
                        <div
                            style={{
                                inset: 0,
                                zIndex: 1,
                            }}
                            // Add Bootstrap classes for padding, centering, etc.
                            className="d-flex flex-column justify-content-center align-items-center p-3 text-center"
                        >
                            {mediakit?.image_main && (
                                <img
                                    src={mediakit.image_main}
                                    alt={mediakit.name}
                                    className="img-fluid rounded-circle mb-3"
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        zIndex: 1000,
                                        objectFit: 'cover',
                                        border: '5px solid white',
                                    }}
                                />
                            )}

                            {/* Responsive heading classes: fs-2 on small screens, fs-1 on md+ screens */}
                            <h1 className="fs-2 fs-md-1 mb-3" style={{zIndex: 1000}} >{mediakit?.name}</h1>

                            {mediakit?.tagline && (
                                <p className="lead mb-4" style={{zIndex: 1000}} >{mediakit.tagline}</p>
                            )}

                            {/* Store Links - allow them to wrap on mobile */}
                            <div className="d-flex flex-wrap justify-content-center gap-2">
                                {storeLinks.map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.url}
                                        style={{zIndex: 1000}}
                                        className={`btn btn-${index % 2 === 0 ? 'primary' : 'outline-light'
                                            } btn-lg`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={link.icon} /> {link.label}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                )}



                {/* If there's a video */}
                {videoEmbedUrl && (
                    <div className="container py-5">
                        <div className="mb-5">
                            <h2 className="text-center mb-1">
                                <FontAwesomeIcon icon={faVideo} />{' '}
                                {!mediakit?.image_banner ? mediakit?.name : 'Trailer'}
                            </h2>
                            {videoEmbedUrl.includes('youtube.com') ||
                                videoEmbedUrl.includes('player.vimeo.com') ? (
                                <div className="ratio ratio-16x9 shadow-lg">
                                    <iframe
                                        src={videoEmbedUrl}
                                        title="Media Kit Video"
                                        allowFullScreen
                                    />
                                </div>
                            ) : (
                                <div className="ratio ratio-16x9 shadow-lg">
                                    <video
                                        controls
                                        src={videoEmbedUrl}
                                        className="w-100 h-100"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Container with content */}
                <div className="container py-5">
                    {/* Short Description */}
                    {mediakit?.short_description && (
                        <div className="mb-5">
                            <h2 className="mb-4">
                                <FontAwesomeIcon icon={faGamepad} /> About the Game
                            </h2>
                            <div
                                className="lead text-start"
                                dangerouslySetInnerHTML={{ __html: mediakit.short_description }}
                            />
                        </div>
                    )}

                    {/* Key Features */}
                    {mediakit?.key_features && (
                        <div className="mb-5">
                            <h2 className="mb-4">
                                <FontAwesomeIcon icon={faStar} /> Key Features
                            </h2>
                            <div
                                className="lead"
                                dangerouslySetInnerHTML={{ __html: mediakit.key_features }}
                            />
                        </div>
                    )}

                    {/* Contact Info */}
                    {(mediakit?.press_contact_name || mediakit?.press_contact_email) && (
                        <div className="mb-5">
                            <h2 className="mb-4">
                                <FontAwesomeIcon icon={faEnvelope} /> Press Contact
                            </h2>
                            {mediakit?.press_contact_name && (
                                <p>
                                    <strong>Name:</strong> {mediakit.press_contact_name}
                                </p>
                            )}
                            {mediakit?.press_contact_email && (
                                <p>
                                    <strong>Email:</strong>{' '}
                                    <a
                                        href={`mailto:${mediakit.press_contact_email}`}
                                        className="text-decoration-none"
                                    >
                                        {mediakit?.press_contact_email}
                                    </a>
                                </p>
                            )}
                        </div>
                    )}

                    {/* Game Info */}
                    {showGameInformation && (
                        <div className="mb-5">
                            <h2 className="mb-4">
                                <FontAwesomeIcon icon={faInfoCircle} /> Game Information
                            </h2>
                            <div className="row">
                                {mediakit?.developer && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Developer:</strong> {mediakit.developer}
                                    </div>
                                )}
                                {mediakit?.publisher && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Publisher:</strong> {mediakit.publisher}
                                    </div>
                                )}
                                {mediakit?.release_date && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Release Date:</strong>{' '}
                                        {new Date(mediakit.release_date).toLocaleDateString()}
                                    </div>
                                )}
                                {mediakit?.age_rating && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Age Rating:</strong> {mediakit.age_rating}
                                    </div>
                                )}
                                {mediakit?.multiplayer_options && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Multiplayer Options:</strong> {mediakit.multiplayer_options}
                                    </div>
                                )}
                                {mediakit?.pricing && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Pricing:</strong> {mediakit.pricing_currency}{' '}
                                        {mediakit.pricing}
                                    </div>
                                )}
                                {mediakit?.availability && (
                                    <div className="col-md-4 mb-3">
                                        <strong>Availability:</strong> {mediakit.availability}
                                    </div>
                                )}
                                {mediakit?.platform_compatibility &&
                                    mediakit?.platform_compatibility.length > 0 && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Platform Compatibility:</strong>{' '}
                                            {mediakit.platform_compatibility}
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}

                    {/* Media Gallery */}
                    {mediaFiles.length > 0 && (
                        <div className="mb-4">
                            <h2 className="text-center mb-4">
                                <FontAwesomeIcon icon={faImages} /> Gallery
                            </h2>
                            <GallerySection mediaFiles={mediaFiles} />
                        </div>
                    )}

                    {/* Additional Info */}
                    {(mediakit?.long_description ||
                        mediakit?.system_requirements ||
                        mediakit?.gameplay_mechanics ||
                        mediakit?.narrative_setting ||
                        mediakit?.visual_audio_style ||
                        mediakit?.dlc_expansion_info ||
                        mediakit?.critical_reception) && (
                            <div className="mb-5">
                                <div
                                    className="d-flex justify-content-between align-items-center mb-3"
                                    onClick={toggleAdditionalInfo}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <h2>
                                        <FontAwesomeIcon icon={faChevronDown} /> Additional Information
                                    </h2>
                                    <FontAwesomeIcon
                                        icon={showAdditionalInfo ? faChevronUp : faChevronDown}
                                        size="2x"
                                    />
                                </div>
                                <Collapse in={showAdditionalInfo}>
                                    <div>
                                        {mediakit?.long_description && (
                                            <div className="mb-4">
                                                <h3>Detailed Description</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.long_description,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.system_requirements && (
                                            <div className="mb-4">
                                                <h3>System Requirements</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.system_requirements,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.gameplay_mechanics && (
                                            <div className="mb-4">
                                                <h3>Gameplay Mechanics</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.gameplay_mechanics,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.narrative_setting && (
                                            <div className="mb-4">
                                                <h3>Narrative Setting</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.narrative_setting,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.visual_audio_style && (
                                            <div className="mb-4">
                                                <h3>Visual and Audio Style</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.visual_audio_style,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.dlc_expansion_info && (
                                            <div className="mb-4">
                                                <h3>DLC and Expansion Info</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.dlc_expansion_info,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {mediakit?.critical_reception && (
                                            <div className="mb-4">
                                                <h3>Critical Reception</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: mediakit.critical_reception,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Collapse>
                            </div>
                        )}
                </div>

                <div className="d-flex flex-column align-items-center text-center">
                    <img src="/android-icon-48x48.png" alt="Icon" />
                    <p className="small">
                        <a href="https://www.glitch.fun">Powered By Glitch</a>
                    </p>
                </div>

                {/* Footer (Optional) */}
                <div className="bg-dark text-white text-center py-4">
                    <p className="mb-0">
                        &copy; {new Date().getFullYear()} {mediakit?.name}. All rights reserved.
                    </p>
                </div>
            </Fragment>
        </>
    );
};

export default MediakitStandalonePage;
