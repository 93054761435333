import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBullhorn,
    faUsers,
    faChartLine,
    faRobot,
    faVideo,
    faImage
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublisherAnalyticsPage() {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    // State for handling enlarged images in a lightbox
    const [selectedImage, setSelectedImage] = useState(null);

    // Opens the modal with the selected image
    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    // Closes the modal
    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>In-Depth Reports & Analytics for Your Game | Glitch Gaming</title>
                <meta
                    name="description"
                    content="Elevate your game’s success with powerful data insights. Our advanced analytics platform consolidates your Steam stats, retention metrics, and marketing funnel data—helping you grow wishlists, refine engagement, and boost sales. Start for free now with Glitch Gaming."
                />
                <meta name="robots" content="index, follow" />
                <meta
                    property="og:title"
                    content="In-Depth Reports & Analytics for Your Game | Glitch Gaming"
                />
                <meta
                    property="og:description"
                    content="Elevate your game’s success with powerful data insights. Our advanced analytics platform consolidates your Steam stats, retention metrics, and marketing funnel data—helping you grow wishlists, refine engagement, and boost sales."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta
                    property="og:image"
                    content="https://www.glitch.fun/assets/images/publishers/game_analytics.webp"
                />
            </Helmet>

            <Header />

            {/* Lightbox Modal */}
            {selectedImage && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999
                    }}
                    onClick={closeModal}
                >
                    <img
                        src={selectedImage}
                        alt="Enlarged"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            border: '2px solid #fff',
                            borderRadius: '5px'
                        }}
                    />
                </div>
            )}

            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br />
                <br />
                <h1 className="mt-5" style={{ fontSize: '2.5rem' }}>
                    In-Depth Reports and Analytics <br /> on Your Game
                </h1>
                <p className="lead">
                    <strong>
                        Analyze every aspect of your game’s performance to make data-driven decisions that guide your next
                        creative move
                    </strong>
                </p>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-sm-6 text-center">
                            <h2 className="mb-4">Get Free Performance Reports</h2>
                            <p className="lead">
                                Run your game like a professional enterprise with transparent reporting on every factor that
                                influences your success:
                            </p>
                        </div>

                        <div className="col-sm-6">
                            <div className="col">
                                <img
                                    src="/assets/images/publishers/wishlist_install_funnels.png"
                                    alt="Wishlist Install Funnels"
                                    className="img-fluid"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => openModal('/assets/images/publishers/wishlist_install_funnels.png')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 text-white" style={{ backgroundColor: '#343a40' }}>
                <div className="container">
                    <h2 className="text-center mb-5">Unleash the Power of Steam Analytics</h2>
                    <div className="row align-items-center">

                        <div className="col-lg-6 text-center">
                            <img
                                src="/assets/images/publishers/steam_analytics.gif"
                                alt="Steam Analytics Dashboard"
                                className="img-fluid rounded mb-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/steam_analytics.gif')}
                            />
                        </div>
                        <div className="col-lg-6">
                            <h3 className="mb-4">Tap into Deep Insights from Your Steam Data</h3>
                            <p className="lead">
                                Steam offers a wealth of metrics across multiple categories. Our platform unites all these
                                analytics into a single, streamlined dashboard—so you can seamlessly fold them into your marketing
                                and development strategies.
                            </p>
                            <ul className="list-unstyled">
                                <li>
                                    <FontAwesomeIcon icon={faBullhorn} className="me-2" />
                                    Identify What Drives Wishlists
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faRobot} className="me-2" />
                                    Amplify Your UTM Tracking
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faChartLine} className="me-2" />
                                    Relate Data Directly to Sales
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <Link className="btn btn-lg btn-danger" to={Navigate.publishersOnboardingStep1Page()}>
                            <FontAwesomeIcon icon={faChartLine} className="me-2" />
                            Get Started with Glitch
                        </Link>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-5 text-black">Track Retention &amp; Active Users</h2>
                    <div className="row text-center text-black">
                        <div className="col-md-6 mb-4">
                            <img
                                src="/assets/images/publishers/charts_dau.png"
                                className="img-fluid"
                                alt="Active Users Chart"
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/charts_dau.png')}
                            />
                            <h4 className="text-black mt-3">Effortlessly Track DAUs, WAUs &amp; MAUs</h4>
                            <p>
                                Check how many players engage with your game daily, weekly, and monthly, and watch how these
                                figures evolve over time.
                            </p>
                        </div>
                        <div className="col-md-6 mb-4">
                            <img
                                src="/assets/images/publishers/charts_retention.png"
                                className="img-fluid"
                                alt="Retention Chart"
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/charts_retention.png')}
                            />
                            <h4 className="text-black mt-3">Analyze Retention</h4>
                            <p>
                                Monitor Day 1, Day 7, Day 15, and Day 30 stats to pinpoint precisely where to refine your
                                gameplay experience.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className="mb-5 pt-4 text-center bg-light rounded"
                style={{ backgroundImage: 'url(/assets/images/cta/bg.jpg)' }}
            >
                <div className="container">
                    <h2 className="mb-4">Analyze How Your Marketing Fuels Growth</h2>
                    <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3 text-success" />
                    <p className="lead">
                        Track every stage of your marketing funnel to understand how social awareness translates into
                        downloads.
                    </p>
                    <img
                        src="/assets/images/publishers/game_funnel.gif"
                        alt="Marketing Funnel"
                        className="img-fluid rounded shadow border border-white border-2"
                        style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
                        onClick={() => openModal('/assets/images/publishers/game_funnel.gif')}
                    />

                    <form className="contact-form mt-4" id="contact-form" method="POST">
                        <div className="form-group w-100 text-center">
                            <Link
                                className="default-button reverse-effect"
                                to={Navigate.publishersOnboardingStep1Page()}
                            >
                                <span>Sign Up for Free</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>

            <section className="py-5 mt-5 mb-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-sm-6 text-center">
                            <img
                                src="/assets/images/publishers/social_growth.jpg"
                                alt="Social Media Growth"
                                className="img-fluid rounded shadow mb-3 border border-white border-2"
                                style={{ height: '300px', cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/social_growth.jpg')}
                            />
                        </div>
                        <div className="col-sm-5">
                            <h2 className="mb-4">Track Your Community Growth on Social Media</h2>
                            <p className="lead">
                                Keep tabs on your social media expansion as you cultivate a vibrant community of fans who
                                can’t wait for your next update.
                            </p>
                            <div className="text-center">
                                <Link
                                    className="default-button reverse-effect mt-3"
                                    to={Navigate.publishersOnboardingStep1Page()}
                                >
                                    <span>Start Tracking Now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IndieSubmenu />
            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/game_analytics.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
};

export default PublisherAnalyticsPage;
