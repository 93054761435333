import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faUsers, faChartLine, faRobot, faVideo, faImage } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublisherCrossPromotePage() {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    // State for handling the "lightbox" style image enlargement
    const [selectedImage, setSelectedImage] = useState(null);

    // Opens the modal with the image
    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    // Closes the modal
    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Boost Your Game’s Reach with Automated Cross-Promotion | Glitch Gaming</title>
                <meta
                    name="description"
                    content="Maximize your game’s exposure with cross-promotion. Connect with other developers, automate content re-sharing, and grow your audience effortlessly. Start boosting your wishlists and sales today!"
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Boost Your Game’s Reach with Automated Cross-Promotion | Glitch Gaming" />
                <meta property="og:description" content="Maximize your game’s exposure with cross-promotion. Connect with other developers, automate content re-sharing, and grow your audience effortlessly. Start boosting your wishlists and sales today!" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/social_scheduler_banner.webp" />
            </Helmet>
            <Header />

            {/* Modal for enlarged images */}
            {selectedImage && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    onClick={closeModal}
                >
                    <img
                        src={selectedImage}
                        alt="Enlarged view"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            border: '2px solid #fff',
                            borderRadius: '5px',
                        }}
                    />
                </div>
            )}

            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 className='mt-5' style={{ fontSize: "2.5rem" }}>Cross-Promote With Other Games</h1>
                <p className="lead"><strong>Grow together with other games by cross-promoting each other's content.</strong></p>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12 text-center">
                            <h2 className="mb-4">We're Giving You <br /> A Secret Weapon AAA Publishers Have</h2>
                            <p className="lead">
                                Large publishers have an unfair advantage by leveraging multiple social accounts to grow each other and share audiences. <br /> <u><strong>But now you can too!</strong></u>
                            </p>

                            <div className='row'>
                                <div className='col'>
                                    <img
                                        src="/assets/images/publishers/x_xbox.jpg"
                                        alt="Xbox"
                                        className="img-fluid"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => openModal('/assets/images/publishers/x_xbox.jpg')}
                                    />
                                </div>
                                <div className='col'>
                                    <img
                                        src="/assets/images/publishers/x_ea.jpg"
                                        alt="EA"
                                        className="img-fluid"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => openModal('/assets/images/publishers/x_ea.jpg')}
                                    />
                                </div>
                                <div className='col'>
                                    <img
                                        src="/assets/images/publishers/x_sega.jpg"
                                        alt="Sega"
                                        className="img-fluid"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => openModal('/assets/images/publishers/x_sega.jpg')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 text-white" style={{ backgroundColor: '#343a40' }}>
                <div className="container">
                    <h2 className="text-center mb-5">Introducing Cross-Promote for Studios and Indies</h2>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h3 className="mb-4">Mutually Beneficial Growth for Games</h3>
                            <p className="lead">By collaborating and co-marketing with other developers, everyone benefits from mutual growth by supporting each other's marketing efforts—and it’s all automated!</p>
                            <ul>
                                <li>Grow your audience size</li>
                                <li>Increase awareness about your game</li>
                                <li>Drive wishlists and sales</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <img
                                src="/assets/images/publishers/several_accouts.png"
                                alt="Combined Strategy"
                                className="img-fluid rounded mb-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/several_accouts.png')}
                            />
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <Link className="btn btn-lg btn-danger" to={Navigate.publishersOnboardingStep1Page()}>
                            Get Started with Glitch
                        </Link>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-2 text-black">Automate Collaboration with up to 20 Other Devs!</h2>
                    <p className='text-black text-center lead'><u>How It Works</u></p>
                    <div className="row text-center text-black">
                        <div className="col-md-4 mb-4">
                            <img
                                src="/assets/images/publishers/search.png"
                                className='img-fluid w-50'
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/search.png')}
                                alt="Search"
                            />
                            <h4 className='text-black'>Step 1: Find Other Games You Want to Work With</h4>
                            <p>We provide a database of games that seem like a great fit to collaborate with.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img
                                src="/assets/images/publishers/scheduler_results.png"
                                className='img-fluid w-50'
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/scheduler_results.png')}
                                alt="Scheduler Results"
                            />
                            <h4 className='text-black'>Step 2: Submit Content at Least Once a Week</h4>
                            <p>Everyone is required to post at least once a week on social media about their game.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img
                                src="/assets/images/publishers/graph_up.png"
                                className='img-fluid w-50 mt-4'
                                style={{ cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/graph_up.png')}
                                alt="Graph Up"
                            />
                            <h4 className='text-black'>Step 3: Watch the Magic Happen with Cross-Promotion</h4>
                            <p>We automate the process of promoting everyone's posts on each other's accounts.</p>
                        </div>
                    </div>

                    <hr className='text-black' />
                    <div className="d-flex justify-content-center align-items-center mb-2" >
                        <h3 className='text-black text-center'>That's It! Your Content Will Go To All The Major Platforms</h3>
                    </div>

                    <div className="col-12 d-flex justify-content-center">
                        <img
                            src={'/assets/images/creators/youtube.png'}
                            alt="YouTube Icon"
                            className="img-fluid mb-2 m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/tiktok.png"
                            alt="TikTok"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/reddit.png"
                            alt="Reddit"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/facebook.png"
                            alt="Facebook"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/twitter.png"
                            alt="Twitter"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/youtube.png"
                            alt="YouTube"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                        <img
                            src="/assets/images/creators/instagram.png"
                            alt="Instagram"
                            className="m-2"
                            style={{ width: '4rem', height: '4rem' }}
                        />
                    </div>
                </div>
            </section>

            <section className="py-5 mt-5 mb-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-sm-6 text-center">
                            <img
                                src="/assets/images/publishers/wishlist_years.png"
                                alt="Dev Log to Social"
                                className="img-fluid rounded shadow mb-3 border border-white border-2"
                                style={{ height: '300px', cursor: 'pointer' }}
                                onClick={() => openModal('/assets/images/publishers/wishlist_years.png')}
                            />
                        </div>
                        <div className="col-sm-5">
                            <h2 className="mb-4">Start Early For Long Term Success</h2>
                            <p className="lead">
                                The longer you grow your audience, the more success you’ll see as your community expands.
                                Organic growth fuels wishlists, which in turn drive sales when you launch.
                            </p>
                            <div className='text-center'>
                                <Link
                                    className="default-button reverse-effect mt-3"
                                    to={Navigate.publishersOnboardingStep1Page()}
                                >
                                    <span>Start Growing Today</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-dark">
                <div className="container">
                    <h2 className="text-center mb-2 text-white">Track Progress With Deep Data Insights</h2>
                    <p className="text-white text-center">
                        You will see cross-promotion work from a data-driven perspective as you track growth down to the tiniest detail while you execute through our data reports.
                    </p>
                    <div className="row text-center text-black">
                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Statistics</h4>
                            <img
                                src="/assets/images/publishers/chart_statistics.png"
                                alt="Statistics"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                                onClick={() => openModal('/assets/images/publishers/chart_statistics.png')}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Comparisons</h4>
                            <img
                                src="/assets/images/publishers/chart_comparison.png"
                                alt="Comparisons"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                                onClick={() => openModal('/assets/images/publishers/chart_comparison.png')}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Wishlists & Installs</h4>
                            <img
                                src="/assets/images/publishers/installs_chart.png"
                                alt="Follower Growth"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                                onClick={() => openModal('/assets/images/publishers/installs_chart.png')}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Engagement & Retention</h4>
                            <img
                                src="/assets/images/publishers/retention_chart.png"
                                alt="Progression"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                                onClick={() => openModal('/assets/images/publishers/retention_chart.png')}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <IndieSubmenu />
            <Footer />
        </>
    );
}

const gatherDataImageStyle = {
    width: '100%',
    maxWidth: '500px',    // Adjust to your liking
    height: '200px',      // All images have the same height
    objectFit: 'contain', // or 'cover', depending on desired look
    display: 'block',
    margin: '0 auto',
    cursor: 'pointer'
};

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/social_scheduler_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
};

export default PublisherCrossPromotePage;
