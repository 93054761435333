import React from "react";
import app_routes from "../constants/app_routes";
import CampaignsStatisticsPage from "../templates/marketing/pages/campaigns/CampaignsStatisticsPage";

/**
 * The url to various pages in the application
 * 
 * Some key notes:
 * 
 * 1. We only want to replace the variables if the variabes being passed in are present, otherwise
 * it should return the :id, which is used by React Router for path matching
 */
const Navigate = {

    //Misc Pages
    homePage: () => {
        return app_routes.home;
    },
    privacyPage: () => {
        return app_routes.privacy;
    },
    termsPage: () => {
        return app_routes.terms;
    },
    contactPage: () => {
        return app_routes.contact;
    },
    aboutPage: () => {
        return app_routes.about;
    },
    dataRemovalPage: () => {
        return app_routes.dataremoval;
    },
    gdprPage: () => {
        return app_routes.gdpr;
    },
    accessDeniedPage: () => {
        return app_routes.access_denied;
    },
    revenuePage: () => {
        return app_routes.revenue;
    },
    marketingPage: () => {
        return app_routes.marketing;
    },
    featuresPage: () => {
        return app_routes.features;
    },
    installationPage: () => {
        return app_routes.installation;
    },
    benefitsPage: () => {
        return app_routes.benefits;
    },
    crawlPage: () => {
        return app_routes.crawl;
    },
    /**
   * =======================
   * AD CAMPAIGNS
   * =======================
   */
    adsCampaignsListPage: () => {
        return app_routes.ads_campaigns_list;
    },
    adsCampaignsCreatePage: () => {
        return app_routes.ads_campaigns_create;
    },
    adsCampaignsViewPage: (campaign_id) => {
        let path = app_routes.ads_campaigns_view;
        if (campaign_id) {
            path = path.replace(':id', campaign_id);
        }
        return path;
    },
    adsCampaignsUpdatePage: (campaign_id) => {
        let path = app_routes.ads_campaigns_update;
        if (campaign_id) {
            path = path.replace(':id', campaign_id);
        }
        return path;
    },
    adsCampaignsDeletePage: (campaign_id) => {
        let path = app_routes.ads_campaigns_delete;
        if (campaign_id) {
            path = path.replace(':id', campaign_id);
        }
        return path;
    },

    /**
     * =======================
     * AD GROUPS
     * =======================
     */
    adsGroupsListPage: (campaign_id) => {
        let path = app_routes.ads_groups_list;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        return path;
    },
    adsGroupsCreatePage: (campaign_id) => {
        let path = app_routes.ads_groups_create;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        return path;
    },
    adsGroupsViewPage: (campaign_id, group_id) => {
        let path = app_routes.ads_groups_view;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':id', group_id);
        }
        return path;
    },
    adsGroupsUpdatePage: (campaign_id, group_id) => {
        let path = app_routes.ads_groups_update;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':id', group_id);
        }
        return path;
    },
    adsGroupsDeletePage: (campaign_id, group_id) => {
        let path = app_routes.ads_groups_delete;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':id', group_id);
        }
        return path;
    },

    /**
     * =======================
     * AD CREATIVES
     * =======================
     */
    adsCreativesListPage: () => {
        return app_routes.ads_creatives_list;
    },
    adsCreativesCreatePage: () => {
        return app_routes.ads_creatives_create;
    },
    adsCreativesViewPage: (creative_id) => {
        let path = app_routes.ads_creatives_view;
        if (creative_id) {
            path = path.replace(':id', creative_id);
        }
        return path;
    },
    adsCreativesUpdatePage: (creative_id) => {
        let path = app_routes.ads_creatives_update;
        if (creative_id) {
            path = path.replace(':id', creative_id);
        }
        return path;
    },
    adsCreativesDeletePage: (creative_id) => {
        let path = app_routes.ads_creatives_delete;
        if (creative_id) {
            path = path.replace(':id', creative_id);
        }
        return path;
    },

    /**
     * =======================
     * AD GROUP TRIGGERS
     * =======================
     */
    adsTriggersListPage: (campaign_id, group_id) => {
        let path = app_routes.ads_triggers_list;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':group_id', group_id);
        }
        return path;
    },
    adsTriggersCreatePage: (campaign_id, group_id) => {
        let path = app_routes.ads_triggers_create;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':group_id', group_id);
        }
        return path;
    },
    adsTriggersViewPage: (campaign_id, group_id, trigger_id) => {
        let path = app_routes.ads_triggers_view;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':group_id', group_id);
        }
        if (trigger_id) {
            path = path.replace(':id', trigger_id);
        }
        return path;
    },
    adsTriggersUpdatePage: (campaign_id, group_id, trigger_id) => {
        let path = app_routes.ads_triggers_update;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':group_id', group_id);
        }
        if (trigger_id) {
            path = path.replace(':id', trigger_id);
        }
        return path;
    },
    adsTriggersDeletePage: (campaign_id, group_id, trigger_id) => {
        let path = app_routes.ads_triggers_delete;
        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id);
        }
        if (group_id) {
            path = path.replace(':group_id', group_id);
        }
        if (trigger_id) {
            path = path.replace(':id', trigger_id);
        }
        return path;
    },


    creatorsPage: () => {
        return app_routes.creators;
    },
    creatorsOverviewPage: () => {
        return app_routes.creators_overview;
    },
    creatorsMicroPage: () => {
        return app_routes.creators_micro;
    },
    creatorsBlackPage: () => {
        return app_routes.creators_black;
    },
    creatorsInfluencersPage: () => {
        return app_routes.creator_influencers;
    },
    creatorsInfluencersMacPage: () => {
        return app_routes.creator_influencers_mac;
    },
    creatorsInfluencersLinuxPage: () => {
        return app_routes.creator_influencers_linux;
    },
    creatorsPublishersPage: () => {
        return app_routes.creator_publishers;
    },
    creatorsPublishersMacPage: () => {
        return app_routes.creator_publishers_mac;
    },
    creatorsPublishersLinuxPage: () => {
        return app_routes.creator_publishers_linux;
    },
    creatorsCalculator: () => {
        return app_routes.creator_calculators;
    },
    creatorsEditing: () => {
        return app_routes.creator_editing;
    },
    creatorsFeatures: () => {
        return app_routes.creator_features;
    },
    creatorsGettingStartedPage: () => {
        return app_routes.creator_getting_started;
    },
    creatorsOnboardingStep1Page: () => {

        return app_routes.creator_onboarding_step_1;

    },
    creatorsOnboardingStep2Page: () => {

        return app_routes.creator_onboarding_step_2;

    },
    creatorsOnboardingStep3Page: () => {

        return app_routes.creator_onboarding_step_3;

    },
    creatorsOnboardingStep4Page: () => {

        return app_routes.creator_onboarding_step_4;

    },
    creatorsOnboardingStep5Page: () => {

        return app_routes.creator_onboarding_step_5;

    },
    creatorsMultistreamingPage: () => {

        return app_routes.creators_multistreaming;

    },
    creatorsOptimizationPage: () => {

        return app_routes.creators_optimization;

    },
    creatorsTiktokstreamPage: () => {

        return app_routes.creators_tiktokstream;

    },

    creatorsPlayTestersPage: () => {

        return app_routes.creators_playtesting;

    },

    //Campaigns

    //Authentication
    authLogin: () => {
        return app_routes.auth_login;
    },
    authRegister: () => {
        return app_routes.auth_register;
    },
    authFacebook: () => {
        return app_routes.auth_facebook;
    },
    authTwitch: () => {
        return app_routes.auth_twitch;
    },
    authStripe: () => {
        return app_routes.auth_stripe;
    },
    authYoutube: () => {
        return app_routes.auth_youtube;
    },
    authGoogle: () => {
        return app_routes.auth_google;
    },
    authMicrosoft: () => {
        return app_routes.auth_microsoft;
    },
    authMicrosoftTeams: () => {
        return app_routes.auth_microsoft_teams;
    },
    authTikTok: () => {
        return app_routes.auth_tiktok;
    },
    authTwitter: () => {
        return app_routes.auth_twitter;
    },
    authReddit: () => {
        return app_routes.auth_reddit;
    },
    authInstagram: () => {
        return app_routes.auth_instagram;
    },
    authDocusign: () => {
        return app_routes.auth_docusign;
    },
    authSimpleSign: () => {
        return app_routes.auth_simplesign;
    },
    authInvitedRegister: () => {
        return app_routes.auth_invited_register;
    },
    authInvitedLogin: () => {
        return app_routes.auth_invited_login;
    },


    authFacebookComplete: () => {
        return app_routes.auth_facebook_complete;
    },
    authTwitchComplete: () => {
        return app_routes.auth_twitch_complete;
    },
    authYoutubeComplete: () => {
        return app_routes.auth_youtube_complete;
    },
    authGoogleComplete: () => {
        return app_routes.auth_google_complete;
    },
    authMicrosoftComplete: () => {
        return app_routes.auth_microsoft_complete;
    },
    authMicrosoftTeamsComplete: () => {
        return app_routes.auth_microsoft_teams_complete;
    },
    authTikTokComplete: () => {
        return app_routes.auth_tiktok_complete;
    },
    authTwitterComplete: () => {
        return app_routes.auth_twitter_complete;
    },
    authRedditComplete: () => {
        return app_routes.auth_reddit_complete;
    },
    authStripeComplete: () => {
        return app_routes.auth_stripe_complete;
    },
    authDocusignComplete: () => {
        return app_routes.auth_docusign_complete;
    },
    authSimpleSignComplete: () => {
        return app_routes.auth_simplesign_complete;
    },



    authForgotPassword: () => {
        return app_routes.auth_forgot_password;
    },
    authResetPassword: () => {
        return app_routes.auth_reset_password;
    },

    //Account Page
    accountMainPage: () => {
        return app_routes.account_update;
    },
    accountRegisterStep2: () => {
        return app_routes.acount_register_page_2;
    },
    accountVerify: (account_id) => {

        let path = app_routes.account_verify;

        if (account_id) {
            path = path.replace(':id', account_id)
        }

        return path;
    },
    joinPage: () => {
        return app_routes.join;
    },

    //Campaigns
    campaignsPage: () => {
        return app_routes.campaigns;
    },
    campaignsCreatePage: () => {
        return app_routes.campaigns_create;
    },
    campaignsUpdatePage: (campaign_id) => {

        let path = app_routes.campaigns_update;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsViewPage: (campaign_id) => {

        let path = app_routes.campaigns_view;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsDeletePage: (campaign_id) => {

        let path = app_routes.campaigns_delete;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },

    campaignsUpdateMediaPage: (campaign_id) => {

        let path = app_routes.campaigns_update_media;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },

    campaignsUpdateLinksPage: (campaign_id) => {

        let path = app_routes.campaigns_update_links;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },

    campaignsRegisterPage: (campaign_id) => {

        let path = app_routes.campaigns_register;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsContentPage: (campaign_id) => {

        let path = app_routes.campaigns_content;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsInfluencersPage: (campaign_id) => {

        let path = app_routes.campaigns_influencers;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsFindInfluencers: (campaign_id) => {

        let path = app_routes.campaigns_find_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsInviteInfluencer: (campaign_id) => {

        let path = app_routes.campaigns_invite_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsRecommendedInfluencers: (campaign_id) => {

        let path = app_routes.campaigns_recommended_influencers;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        return path;
    },
    campaignsViewInfluencer: (campaign_id, influencer_id) => {

        let path = app_routes.campaigns_view_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    campaignsUpdateInfluencer: (campaign_id, influencer_id) => {

        let path = app_routes.campaigns_update_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    campaignsUpdateInfluencerInviteCompensation: (campaign_id, influencer_id) => {

        let path = app_routes.campaigns_update_influencer_invite_compensation;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    campaignsUpdateInfluencerInviteContract: (campaign_id, influencer_id) => {

        let path = app_routes.campaigns_update_influencer_invite_contract;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    campaignsViewInfluencerInvite: (campaign_id, influencer_id) => {

        let path = app_routes.campaigns_influencer_invite;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    campaignsResearchInfluencer: (campaign_id, user_id) => {

        let path = app_routes.campaigns_research_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    campaignsManageInfluencer: (campaign_id, user_id) => {

        let path = app_routes.campaigns_manage_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    campaignsPerformanceInfluencer: (campaign_id, user_id) => {

        let path = app_routes.campaigns_performance_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    campaignsContractsInfluencer: (campaign_id, user_id) => {

        let path = app_routes.campaigns_contracts_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    campaignsPaymentInfluencer: (campaign_id, user_id) => {

        let path = app_routes.campaigns_payment_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    campaignsMessageInfluencer: (campaign_id, user_id, otherUsers = []) => {
        let path = app_routes.campaigns_message_influencer;

        if (campaign_id) {
            path = path.replace(':id', campaign_id);
        }

        if (user_id) {
            path = path.replace(':user_id', user_id);
        }

        // Check if otherUsers has data and append it as a query string
        if (otherUsers.length > 0) {
            // Assuming path does not already contain a '?', if it might, this logic needs to be adjusted.
            const queryParams = new URLSearchParams();
            queryParams.append('participants', otherUsers.join(','));
            path += `?${queryParams.toString()}`;
        }

        return path;
    },
    campaignsStartPage: (campaign_id) => {

        let path = app_routes.campaigns_start;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }


        return path;
    },
    campaignsInvitesPage: (campaign_id) => {

        let path = app_routes.campaigns_invites;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }


        return path;
    },
    campaignsLedgerPage: (campaign_id) => {

        let path = app_routes.campaigns_ledger;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }


        return path;
    },
    campaignsStatisticsPage: (campaign_id) => {

        let path = app_routes.campaigns_statistics;

        if (campaign_id) {
            path = path.replace(':id', campaign_id)
        }


        return path;
    },


    //Communites Pages
    communitiesPage: () => {
        return app_routes.communities;
    },
    communitiesAdminListPage: () => {
        return app_routes.communities_admin;
    },
    communitiesCreatePage: () => {
        return app_routes.communities_create;
    },
    communitiesUpdatePage: (community_id) => {

        let path = app_routes.communities_update;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesViewPage: (community_id) => {

        let path = app_routes.communities_view;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesDeletePage: (community_id) => {

        let path = app_routes.communities_delete;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesOverviewPage: (community_id) => {

        let path = app_routes.communities_overview;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesManagePage: (community_id) => {

        let path = app_routes.communities_manage;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesAccessibilityPage: (community_id) => {

        let path = app_routes.communities_accessibility;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesNewsletterPage: (community_id) => {

        let path = app_routes.communities_newsletter;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesSocialPage: (community_id) => {

        let path = app_routes.communities_social;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesFeaturesPage: (community_id) => {

        let path = app_routes.communities_features;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesCnamePage: (community_id) => {

        let path = app_routes.communities_cname;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesMediaPage: (community_id) => {

        let path = app_routes.communities_media;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesCssPage: (community_id) => {

        let path = app_routes.communities_css;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesEmailsPage: (community_id) => {

        let path = app_routes.communities_emails;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesContractstPage: (community_id) => {

        let path = app_routes.communities_contracts;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesUsersListPage: (community_id) => {

        let path = app_routes.communities_users;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesUsersManagePage: (community_id, user_id) => {

        let path = app_routes.communities_user;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    communitiesUsersInvitePage: (community_id) => {

        let path = app_routes.communities_invite;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesInvitedLoginPage: (community_id) => {

        let path = app_routes.communities_invited_login;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },

    communitiesInvitedRegisterPage: (community_id) => {

        let path = app_routes.communities_invited_register

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesSubscribePage: (community_id) => {

        let path = app_routes.communities_subscribe

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesSubscriptionsPage: (community_id) => {

        let path = app_routes.communities_subscriptions

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesCardsPage: (community_id) => {

        let path = app_routes.communities_cards;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesEmailTemplatesPage: (community_id) => {

        let path = app_routes.communities_email_templates;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesGettingStartedPage: (community_id) => {

        let path = app_routes.communities_getting_started;

        if (community_id) {
            path = path.replace(':id', community_id)
        }

        return path;
    },
    communitiesNewsletterStandalone: (newsletter_id) => {

        let path = app_routes.newsletters_community_subscribe_standalone;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        return path;
    },
    communitiesNewsletterForm: (newsletter_id) => {

        let path = app_routes.newsletters_community_subscribe_form;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        return path;
    },

    influencersListCampaignsPage: () => {

        let path = app_routes.influencer_campaigns;

        return path;
    },

    influencersMyCampaignsPage: () => {

        let path = app_routes.influencer_my_campaigns;

        return path;
    },

    influencersFindCampaignPage: () => {

        let path = app_routes.influencer_find_campaigns;

        return path;
    },

    influencersViewCampaignPage: (campaign_id) => {

        let path = app_routes.influencer_view_campaign;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    influencersPayoutsCampaignPage: (campaign_id) => {

        let path = app_routes.influencer_campaign_payouts;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    influencersBeginCampaignPage: (campaign_id) => {

        let path = app_routes.influencer_campaign_begin;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    influencersApplyCampaignPage: (campaign_id) => {

        let path = app_routes.influencer_campaign_apply;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },

    influencersManageCampaignPage: (campaign_id, user_id) => {

        let path = app_routes.influencer_manage_campaign;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },

    influencersCampaignInvitePage: (campaign_id, influencer_id) => {

        let path = app_routes.influencer_campaign_invite;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        if (influencer_id) {
            path = path.replace(':influencer_id', influencer_id)
        }

        return path;
    },
    influencersCampaignPostsCreatePage: (campaign_id) => {

        let path = app_routes.influencer_campaign_posts_create;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    influencersCampaignPostsListPage: (campaign_id) => {

        let path = app_routes.influencer_campaign_posts_list;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    influencersCampaignPostsUpdatePage: (campaign_id, post_id) => {

        let path = app_routes.influencer_campaign_posts_update;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        if (post_id) {
            path = path.replace(':post_id', post_id)
        }

        return path;
    },
    influencersCampaignPostsViewPage: (campaign_id, post_id) => {

        let path = app_routes.influencer_campaign_posts_view;

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        if (post_id) {
            path = path.replace(':post_id', post_id)
        }

        return path;
    },

    influencersMessagesListPage: () => {
        return app_routes.influencer_messages;
    },
    influencersMessagesCreatePage: () => {
        return app_routes.influencer_message_create;
    },
    influencersMessagesThreadPage: (thread_id) => {

        let path = app_routes.influencer_message_thread;

        if (thread_id) {
            path = path.replace(':id', thread_id)
        }

        return path;
    },

    influencersDownloadAppPage: () => {
        return app_routes.influencer_download_app
    },
    influencersGettingStartedPage: () => {
        return app_routes.influencer_getting_started
    },

    influencersTutorials: () => {
        return app_routes.influencer_tutorials;
    },
    influencersTutorialInvites: () => {
        return app_routes.influencer_tutorials_invites;
    },
    influencersTutorialGames: () => {
        return app_routes.influencer_tutorials_games;
    },
    influencersTutorialStarting: () => {
        return app_routes.influencer_tutorials_starting;
    },


    //Messages
    messagesListPage: () => {
        return app_routes.messages;
    },
    messagesCreatePage: () => {
        return app_routes.message_create;
    },
    messagesReadPage: (message_id) => {

        let path = app_routes.message_read;

        if (message_id) {
            path = path.replace(':id', message_id)
        }

        return path;
    },
    messagesThreadPage: (thread_id) => {

        let path = app_routes.message_thread;

        if (thread_id) {
            path = path.replace(':id', thread_id)
        }

        return path;
    },

    //Newsletter

    newsletterListPage: () => {
        return app_routes.newsletters_list;
    },
    newsletterCreatePage: () => {
        return app_routes.newsletters_create;
    },
    newsletterUpdatePage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_update;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterViewPage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_view;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterSubscribePage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_subscribe;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterSubscribersPage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_subscribers;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterImportPage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_import;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterReportsPage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_reports;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },


    newsletterCampaignListPage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_campaigns_list;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterCampaignCreatePage: (newsletter_id, community_id) => {
        let path = app_routes.newsletters_campaigns_create;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        return path;
    },
    newsletterCampaignUpdatePage: (newsletter_id, community_id, campaign_id) => {
        let path = app_routes.newsletters_campaigns_update;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    newsletterCampaignViewPage: (newsletter_id, community_id, campaign_id) => {
        let path = app_routes.newsletters_campaigns_view;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },
    newsletterCampaignSendPage: (newsletter_id, community_id, campaign_id) => {
        let path = app_routes.newsletters_campaigns_send;

        if (newsletter_id) {
            path = path.replace(':id', newsletter_id)
        }

        if (community_id) {
            path = path.replace(':community_id', community_id)
        }

        if (campaign_id) {
            path = path.replace(':campaign_id', campaign_id)
        }

        return path;
    },


    //Posts
    postsListPage: () => {
        return app_routes.posts;
    },
    postsCreatePage: () => {
        return app_routes.posts_create;
    },
    postsUpdatePage: (post_id) => {

        let path = app_routes.posts_update;

        if (post_id) {
            path = path.replace(':id', post_id)
        }

        return path;
    },
    postsViewPage: (post_id) => {

        let path = app_routes.posts_view;

        if (post_id) {
            path = path.replace(':id', post_id)
        }

        return path;
    },
    postsDeletePage: (post_id) => {

        let path = app_routes.posts_delete;

        if (post_id) {
            path = path.replace(':id', post_id)
        }

        return path;
    },


    publishersPage: () => {

    },
    publishersGamificationPage: () => {

        return app_routes.publishers_gamifications;

    },
    publishersGamificationPage2: () => {

        return app_routes.publishers_gamifications_2;

    },
    publishersPilotPage: () => {

        return app_routes.publishers_pilots;

    },
    publishersDatabasePage: () => {

        return app_routes.publishers_database;

    },
    publishersDatabasePage2: () => {

        return app_routes.publishers_database_2;

    },
    publishersAIPage: () => {

        return app_routes.publishers_ai;

    },
    publishersAIPage2: () => {

        return app_routes.publishers_ai_2;

    },
    publishersCRMPage: () => {

        return app_routes.publishers_crm;

    },
    publishersCRMPage2: () => {

        return app_routes.publishers_crm_2;

    },
    publishersContentPage: () => {

        return app_routes.publishers_content;

    },
    publishersHoldaysPage: () => {

        return app_routes.publishers_holidays;

    },
    publishersPlayTesterPage: () => {

        return app_routes.publishers_playtesters;

    },
    publishersNewsletterPage: () => {

        return app_routes.publishers_newsletter;

    },
    publishersShowcasesPage: () => {

        return app_routes.publishers_showcases;

    },
    publishersMediakitPage: () => {

        return app_routes.publishers_mediakit;

    },
    publishersDevlogsPage: () => {

        return app_routes.publishers_devlogs;

    },
    publishersSocialPage: () => {

        return app_routes.publishers_social;

    },
    publishersCrossPromotePage: () => {

        return app_routes.publishers_crosspromote;

    },
    publishersAnalyticsPage: () => {

        return app_routes.publishers_analytics;

    },
    publishersCommunityPage: () => {

        return app_routes.publishers_community;

    },
    publishersOverviewPage: () => {

        return app_routes.publishers_overview;

    },
    publishersPressPage: () => {

        return app_routes.publishers_press;

    },
    publishersOptimizationPage: () => {

        return app_routes.publishers_optimization;

    },
    publishersOptimizationPage2: () => {

        return app_routes.publishers_optimization_2;

    },
    publishersBenefitsPage: () => {

        return app_routes.publishers_benefits;

    },
    publishersBenefitsPage2: () => {

        return app_routes.publishers_benefits_2;

    },
    publishersAcquisitionPage: () => {

        return app_routes.publishers_acquisition;

    },
    publishersAcquisitionPage2: () => {

        return app_routes.publishers_acquisition_2;

    },
    publishersDashboardPage: () => {

        return app_routes.publishers_dashboard;

    },
    publishersToolsPricingPage: () => {

        return app_routes.publishers_tools_pricing;

    },
    publishersToolsMediaPage: () => {

        return app_routes.publishers_tools_media;

    },
    publishersToolsPRPage: () => {

        return app_routes.publishers_tools_pr;

    },
    publishersToolsWikiPage: () => {

        return app_routes.publishers_tools_wiki;

    },
    publishersToolsDatabasePage: () => {

        return app_routes.publishers_tools_database;

    },
    publishersToolsInfluencersPage: () => {

        return app_routes.publishers_tools_influencers;

    },
    publishersToolsShowcasesPage: () => {

        return app_routes.publishers_tools_showcases;

    },
    publishersToolsForumPage: () => {

        return app_routes.publishers_tools_forums;

    },
    publishersToolsPage: () => {

        return app_routes.publishers_tools;

    },
    publishersToolsGuidePage: () => {

        return app_routes.publishers_tools_guide;

    },
    publishersToolsEmailPage: () => {

        return app_routes.publishers_tools_email;

    },
    publishersRegisterPage: () => {

        return app_routes.publishers_register;

    },
    publishersPricingPage: () => {

        return app_routes.publishers_pricing;

    },
    publishersOnboardingStep1Page: () => {

        return app_routes.publishers_onboarding_step_1;

    },
    publishersOnboardingStep2Page: () => {

        return app_routes.publishers_onboarding_step_2;

    },
    publishersOnboardingStep3Page: () => {

        return app_routes.publishers_onboarding_step_3;

    },
    publishersOnboardingStep4Page: () => {

        return app_routes.publishers_onboarding_step_4;

    },
    publishersOnboardingStep5Page: () => {

        return app_routes.publishers_onboarding_step_5;

    },
    publishersMessagesListPage: () => {
        return app_routes.publishers_messages;
    },
    publishersMessagesCreatePage: () => {
        return app_routes.publishers_message_create;
    },
    publishersMessagesThreadPage: (thread_id) => {

        let path = app_routes.publishers_message_thread;

        if (thread_id) {
            path = path.replace(':id', thread_id)
        }

        return path;
    },

    publishersTutorialsPage: () => {
        return app_routes.publishers_tutorials;
    },
    publishersTutorialsCampaignsOverviewPage: () => {
        return app_routes.publishers_tutorials_campaigns_overview;
    },
    publishersTutorialsCampaignsCreatePage: () => {
        return app_routes.publishers_tutorials_campaigns_create;
    },
    publishersTutorialsCampaignsChecklistPage: () => {
        return app_routes.publishers_tutorials_campaigns_checklist;
    },
    publishersTutorialsCampaignsRatecardPage: () => {
        return app_routes.publishers_tutorials_campaigns_ratecard;
    },
    publishersTutorialsInvitesPipelinesPage: () => {
        return app_routes.publishers_tutorials_invites_pipelines;
    },
    publishersTutorialsInvitesNotesPage: () => {
        return app_routes.publishers_tutorials_invites_notes;
    },
    publishersTutorialsInvitesInvitePage: () => {
        return app_routes.publishers_tutorials_invites_invites;
    },
    publishersTutorialsInvitesCustomPage: () => {
        return app_routes.publishers_tutorials_invites_custom_invites;
    },
    publishersTutorialsInvitesRecommendedPage: () => {
        return app_routes.publishers_tutorials_invites_recommended;
    },
    publishersTutorialsInvitesNegotiationsPage: () => {
        return app_routes.publishers_tutorials_invites_custom_negotiations;
    },
    publishersTutorialsInvitesDatabasePage: () => {
        return app_routes.publishers_tutorials_invites_database;
    },

    publishersTutorialsSchedulersCreatePage: () => {
        return app_routes.publishers_tutorials_schedulers_create;
    },

    //Showcases
    gameshowPublisherListPage: () => {

        let path = app_routes.publishers_gameshows_mine;

        return path;

    },
    gameshowPublisherCreatePage: () => {

        let path = app_routes.publishers_gameshows_create;

        return path;

    },
    gameshowPublisherUpdatePage: (show_id) => {

        let path = app_routes.publishers_gameshows_update;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherViewPage: (show_id) => {

        let path = app_routes.publishers_gameshows_view;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherDeletePage: (show_id) => {

        let path = app_routes.publishers_gameshows_delete;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },

    gameshowPublisherRegisterGamePage: (show_id) => {

        let path = app_routes.publishers_gameshows_register;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherGamesPage: (show_id) => {

        let path = app_routes.publishers_gameshows_games;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherUsersPage: (show_id) => {

        let path = app_routes.publishers_gameshows_users;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherViewGamePage: (show_id, game_id) => {

        let path = app_routes.publishers_gameshows_viewgame;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        if (game_id) {
            path = path.replace(':game_id', game_id)
        }

        return path;

    },
    gameshowPublisherViewUserPage: (show_id, user_id) => {

        let path = app_routes.publishers_gameshows_viewuser;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;

    },
    gameshowPublisherUpdateGamePage: (show_id) => {

        let path = app_routes.publishers_gameshows_updategame;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowPublisherPromotePage: (show_id) => {

        let path = app_routes.publishers_gameshows_promote;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityViewStandalonePage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_view_standalone;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityViewCommunityPage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_view_community;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityRegisterDevelopersStandalonePage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_register_developer_standalone;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityRegisterDevelopersCommunityPage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_register_developer_community;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityRegisterUsersStandalonePage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_register_user_standalone;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },
    gameshowCommunityRegisterUsersCommunityPage: (show_id) => {

        let path = app_routes.publishers_community_gameshows_register_user_community;

        if (show_id) {
            path = path.replace(':id', show_id)
        }

        return path;

    },

    //Mediakits

    mediakitsPublishersListPage: () => {
        return app_routes.mediakits_publisher_list;
    },
    mediakitsPublishersCreatePage: () => {
        return app_routes.mediakits_publisher_create;
    },
    mediakitsPublishersUpdatePage: (mediakit_id) => {

        let path = app_routes.mediakits_publisher_update;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },
    mediakitsPublishersViewPage: (mediakit_id) => {

        let path = app_routes.mediakits_publisher_view;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },
    mediakitsPublishersDeletePage: (mediakit_id) => {

        let path = app_routes.mediakits_publisher_delete;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },
    mediakitsPublishersSharePage: (mediakit_id) => {

        let path = app_routes.mediakits_publisher_share;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },
    mediakitsCommunityStandalonePage: (mediakit_id) => {

        let path = app_routes.mediakits_community_standalone;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },
    mediakitsCommunityCommunityPage: (mediakit_id) => {

        let path = app_routes.mediakits_community_community;

        if (mediakit_id) {
            path = path.replace(':id', mediakit_id)
        }

        return path;
    },

    //Playtesting

    playtestingPublishersListPage: () => {
        return app_routes.playtesting_publisher_list;
    },
    playtestingPublishersCreatePage: () => {
        return app_routes.playtesting_publisher_create;
    },
    playtestingPublishersUpdatePage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_update;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersViewPage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_view;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersTestersPage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_testers;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersInvitePage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_invite;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },

    playtestingPublishersViewTestPage: (title_id, playtesting_id) => {

        let path = app_routes.playtesting_publisher_viewtest;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtesting_id) {
            path = path.replace(':playtest_id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersViewQuestionsPage: (title_id, playtesting_id) => {

        let path = app_routes.playtesting_publisher_questions;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtesting_id) {
            path = path.replace(':playtest_id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersViewReviewPage: (title_id, playtesting_id) => {

        let path = app_routes.playtesting_publisher_review;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtesting_id) {
            path = path.replace(':playtest_id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersMessagesPage: (title_id, playtesting_id) => {

        let path = app_routes.playtesting_publisher_messages;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtesting_id) {
            path = path.replace(':playtest_id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersDeletePage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_delete;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersSharePage: (playtesting_id) => {

        let path = app_routes.playtesting_publisher_share;

        if (playtesting_id) {
            path = path.replace(':id', playtesting_id)
        }

        return path;
    },
    playtestingPublishersPreviewPage: (title_id) => {

        let path = app_routes.playtesting_publisher_preview;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },


    playtestingUserGames: () => {

        let path = app_routes.playtesting_user_games;


        return path;
    },
    playtestingUserTests: () => {

        let path = app_routes.playtesting_user_tests;


        return path;
    },
    playtestingUserGame: (game_id) => {

        let path = app_routes.playtesting_user_game;

        if (game_id) {
            path = path.replace(':id', game_id)
        }

        return path;
    },
    playtestingUserAnswers: (title_id, playtest_id) => {

        let path = app_routes.playtesting_user_answers;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtest_id) {
            path = path.replace(':playtest_id', playtest_id)
        }

        return path;
    },
    playtestingUserTest: (title_id, playtest_id) => {

        let path = app_routes.playtesting_user_info;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        if (playtest_id) {
            path = path.replace(':playtest_id', playtest_id)
        }

        return path;
    },

    //Reports Pages
    reportsMainPage: () => {
        return app_routes.reports_main_page;
    },
    reportsListTitlesPage: () => {
        return app_routes.reports_list_titles_page;
    },
    reportsListCommunitiesPage: () => {
        return app_routes.reports_list_communities_page;
    },
    reportsViewTitlePage: (title_id) => {
        let path = app_routes.reports_view_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsUploadTitlePage: (title_id) => {
        let path = app_routes.reports_upload_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsCodeTitlePage: (title_id) => {
        let path = app_routes.reports_code_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsTokensTitlePage: (title_id) => {
        let path = app_routes.reports_tokens_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsOAuthTitlePage: (title_id) => {
        let path = app_routes.reports_oauth_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsChatTitlePage: (title_id) => {
        let path = app_routes.reports_chat_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsChatMockTitlePage: (title_id) => {
        let path = app_routes.reports_chat_mock_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsSessionsTitlePage: (title_id) => {
        let path = app_routes.reports_session_title_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },
    reportsViewCommunityPage: (title_id) => {
        let path = app_routes.reports_view_community_page;

        if (title_id) {
            path = path.replace(':id', title_id)
        }

        return path;
    },

    //Scheduler Pages
    schedulerListPage: () => {
        return app_routes.schedulers_list;
    },
    schedulerCreatePage: () => {
        return app_routes.schedulers_create;
    },
    schedulerUpdatePage: (scheduler_id) => {
        let path = app_routes.schedulers_update;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerViewPage: (scheduler_id) => {
        let path = app_routes.schedulers_view;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerPostsPage: (scheduler_id) => {
        let path = app_routes.schedulers_posts;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerSocialsPage: (scheduler_id) => {
        let path = app_routes.schedulers_socials;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerUsersPage: (scheduler_id) => {
        let path = app_routes.schedulers_users;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerInvitePage: (scheduler_id) => {
        let path = app_routes.schedulers_invite;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerTonePage: (scheduler_id) => {
        let path = app_routes.schedulers_tone;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerReportsPage: (scheduler_id) => {
        let path = app_routes.schedulers_reports;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerBrandingPage: (scheduler_id) => {
        let path = app_routes.schedulers_branding;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerGoalsPage: (scheduler_id) => {
        let path = app_routes.schedulers_goals;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerUpdatesListPage: (scheduler_id) => {
        let path = app_routes.schedulers_update_list;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerUpdatesCreatePage: (scheduler_id) => {
        let path = app_routes.schedulers_update_create;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerUpdatesViewPage: (scheduler_id, update_id) => {
        let path = app_routes.schedulers_update_view;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        if (update_id) {
            path = path.replace(':update_id', update_id)
        }

        return path;
    },
    schedulerUpdatesUpdatePage: (scheduler_id, update_id) => {
        let path = app_routes.schedulers_update_update;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        if (update_id) {
            path = path.replace(':update_id', update_id)
        }

        return path;
    },
    schedulerPostsCreatePage: (scheduler_id) => {
        let path = app_routes.schedulers_posts_create;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }


        return path;
    },
    schedulerPostsUpdatePage: (scheduler_id, post_id) => {
        let path = app_routes.schedulers_posts_update;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        if (post_id) {
            path = path.replace(':post_id', post_id)
        }

        return path;
    },
    schedulerPostsViewPage: (scheduler_id, post_id) => {
        let path = app_routes.schedulers_posts_view;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        if (post_id) {
            path = path.replace(':post_id', post_id)
        }

        return path;
    },
    schedulerCrossPromoteMainPage: (scheduler_id) => {
        let path = app_routes.schedulers_cross_promote_main;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerCrossPromoteFindPage: (scheduler_id) => {
        let path = app_routes.schedulers_cross_promote_find;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerCrossPromoteInvitesPage: (scheduler_id) => {
        let path = app_routes.schedulers_cross_promote_invites;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },
    schedulerCrossPromoteManagePage: (scheduler_id) => {
        let path = app_routes.schedulers_cross_promote_manage;

        if (scheduler_id) {
            path = path.replace(':id', scheduler_id)
        }

        return path;
    },


    //Stream Pages
    streamsPage: () => {
        return app_routes.streams;
    },
    streamsCreatePage: () => {
        return app_routes.streams_create;
    },
    streamsUpdatePage: (event_id) => {

        let path = app_routes.streams_create;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;
    },
    streamsWatchPage: (event_id) => {

        let path = app_routes.streams_watch;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;
    },
    streamsBroadcastPage: (event_id) => {

        let path = app_routes.streams_broadcast;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;
    },
    streamsDeletePage: (event_id) => {

        let path = app_routes.streams_delete;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;
    },
    streamsCohostPassword: (event_id, passcode) => {

        let path = app_routes.streams_cohost_password;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        if (passcode) {
            path = path.replace(':passcode', passcode)
        }

        return path;
    },
    streamsCohostWatch: (event_id) => {

        let path = app_routes.streams_cohost_watch;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;
    },
    streamsWatchRecordingPage: (event_id, recording_id) => {

        let path = app_routes.streams_recording_watch;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        if (recording_id) {
            path = path.replace(':subid', recording_id)
        }

        return path;
    },
    streamsManageRecordingPage: (event_id, recording_id) => {

        let path = app_routes.streams_recording_update;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        if (recording_id) {
            path = path.replace(':subid', recording_id)
        }

        return path;
    },

    //Teams Page
    teamsList: () => {

        let path = app_routes.teams;

        return path;

    },
    teamsCreate: () => {

        let path = app_routes.teams_create;

        return path;

    },
    teamsUpdate: (team_id) => {

        let path = app_routes.teams_update;

        if (team_id) {
            path = path.replace(':id', team_id)
        }

        return path;

    },
    teamsView: (team_id) => {

        let path = app_routes.teams_view;

        if (team_id) {
            path = path.replace(':id', team_id)
        }

        return path;

    },
    teamsDelete: (team_id) => {

        let path = app_routes.teams_delete;

        if (team_id) {
            path = path.replace(':id', team_id)
        }

        return path;

    },
    teamsRegister: (team_id) => {

        let path = app_routes.teams_register;

        if (team_id) {
            path = path.replace(':id', team_id)
        }

        return path;

    },

    //tips
    tipsEmojiOnly: (event_id) => {

        let path = app_routes.tips_emojis;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;

    },
    tipsEmojiAndWatch: (event_id) => {

        let path = app_routes.tips_watch_emojis;

        if (event_id) {
            path = path.replace(':id', event_id)
        }

        return path;

    },

    //Tourmanents
    tournamentsList: () => {

        let path = app_routes.tournaments;

        return path;

    },
    tournamentsCreate: () => {

        let path = app_routes.tournaments_create;

        return path;

    },
    tournamentsUpdate: (tournament_id) => {

        let path = app_routes.tournaments_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;

    },
    tournamentsUpdateWaivers: (tournament_id) => {

        let path = app_routes.tournaments_update_waivers;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;

    },
    tournamentsUpdateMedia: (tournament_id) => {

        let path = app_routes.tournaments_update_media;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;

    },
    tournamentsView: (tournament_id) => {

        let path = app_routes.tournaments_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;

    },
    tournamentsDelete: (tournament_id) => {

        let path = app_routes.tournaments_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;

    },
    tournamentsRegisterUser: (tournament_id) => {

        let path = app_routes.tournaments_register_user;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsRegisterTeam: (tournament_id) => {

        let path = app_routes.tournaments_register_team;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsManage: (tournament_id) => {

        let path = app_routes.tournaments_manage;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsVenuesList: (tournament_id) => {

        let path = app_routes.tournaments_venues;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsVenuesCreate: (tournament_id) => {

        let path = app_routes.tournaments_venues_create;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsVenuesUpdate: (tournament_id, venue_id) => {

        let path = app_routes.tournaments_venues_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (venue_id) {
            path = path.replace(':venue_id', venue_id)
        }

        return path;
    },
    tournamentsVenuesView: (tournament_id, venue_id) => {

        let path = app_routes.tournaments_venues_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (venue_id) {
            path = path.replace(':venue_id', venue_id)
        }

        return path;
    },
    tournamentsVenuesDelete: (tournament_id, venue_id) => {

        let path = app_routes.tournaments_venues_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (venue_id) {
            path = path.replace(':venue_id', venue_id)
        }

        return path;
    },
    tournamentsRoundsList: (tournament_id) => {

        let path = app_routes.tournaments_rounds;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsRoundsCreate: (tournament_id) => {

        let path = app_routes.tournaments_rounds_create;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsRoundsUpdate: (tournament_id, round_id) => {

        let path = app_routes.tournaments_rounds_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        return path;
    },
    tournamentsRoundsView: (tournament_id, round_id) => {

        let path = app_routes.tournaments_rounds_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        return path;
    },
    tournamentsRoundsDelete: (tournament_id, round_id) => {

        let path = app_routes.tournaments_rounds_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        return path;
    },
    tournamentsRoundBracketsList: (tournament_id, round_id) => {

        let path = app_routes.tournaments_brackets;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        return path;
    },
    tournamentsRoundBracketsCreate: (tournament_id, round_id) => {

        let path = app_routes.tournaments_brackets_create;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        return path;
    },
    tournamentsRoundBracketsUpdate: (tournament_id, round_id, bracket_id) => {

        let path = app_routes.tournaments_brackets_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        if (bracket_id) {
            path = path.replace(':bracket_id', bracket_id)
        }

        return path;
    },
    tournamentsRoundBracketsView: (tournament_id, round_id, bracket_id) => {

        let path = app_routes.tournaments_brackets_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        if (bracket_id) {
            path = path.replace(':bracket_id', bracket_id)
        }

        return path;
    },
    tournamentsRoundBracketsDelete: (tournament_id, round_id, bracket_id) => {

        let path = app_routes.tournaments_brackets_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (round_id) {
            path = path.replace(':round_id', round_id)
        }

        if (bracket_id) {
            path = path.replace(':bracket_id', bracket_id)
        }

        return path;
    },
    tournamentsUsersList: (tournament_id) => {

        let path = app_routes.tournaments_users;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsUsersCreate: (tournament_id) => {

        let path = app_routes.tournaments_users_create;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsUsersUpdate: (tournament_id, user_id) => {

        let path = app_routes.tournaments_users_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    tournamentsUsersView: (tournament_id, user_id) => {

        let path = app_routes.tournaments_users_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    tournamentsUsersDelete: (tournament_id, user_id) => {

        let path = app_routes.tournaments_users_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (user_id) {
            path = path.replace(':user_id', user_id)
        }

        return path;
    },
    tournamentsUsersInvite: (tournament_id) => {

        let path = app_routes.tournaments_invite;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsTeamsList: (tournament_id) => {

        let path = app_routes.tournaments_teams;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsTeamsCreate: (tournament_id) => {

        let path = app_routes.tournaments_teams_create;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        return path;
    },
    tournamentsTeamsUpdate: (tournament_id, team_id) => {

        let path = app_routes.tournaments_teams_update;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (team_id) {
            path = path.replace(':team_id', team_id)
        }

        return path;
    },
    tournamentsTeamsView: (tournament_id, team_id) => {

        let path = app_routes.tournaments_teams_view;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (team_id) {
            path = path.replace(':team_id', team_id)
        }

        return path;
    },
    tournamentsTeamsDelete: (tournament_id, team_id) => {

        let path = app_routes.tournaments_teams_delete;

        if (tournament_id) {
            path = path.replace(':id', tournament_id)
        }

        if (team_id) {
            path = path.replace(':team_id', team_id)
        }

        return path;
    },
    tournamentsTypes: () => {

        let path = app_routes.tournaments_types;

        return path;
    },


    //Users
    usersList: () => {

        let path = app_routes.users_list;

        return path;

    },
    usersProfilePage: (user_id) => {

        let path = app_routes.users_profile;

        if (user_id) {
            path = path.replace(':id', user_id)
        }

        return path;

    },
    usersFollowers: (user_id) => {

        let path = app_routes.users_followers;

        if (user_id) {
            path = path.replace(':id', user_id)
        }

        return path;

    },
    usersFollowing: (user_id) => {

        let path = app_routes.users_following;

        if (user_id) {
            path = path.replace(':id', user_id)
        }

        return path;

    },
    usersInvitesPage: (user_id) => {

        let path = app_routes.users_invites;

        if (user_id) {
            path = path.replace(':id', user_id)
        }

        return path;

    },
    usersPayoutsPage: (user_id) => {

        let path = app_routes.users_payouts;

        if (user_id) {
            path = path.replace(':id', user_id)
        }

        return path;

    },

}

export default Navigate;