import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitle from '../../component/section/titles/title_display';
import Alerts from '../../../../util/Alerts';
import { Helmet } from 'react-helmet-async';

const FALLBACK_IMAGE = 'https://www.glitch.fun/assets/images/titles/no_image_2.png';


const stripHtml = (html) => {
    if (!html) return '';

    // Use browser-specific DOMParser if available
    if (typeof window !== 'undefined' && typeof DOMParser !== 'undefined') {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    // Use a fallback for Node.js
    // Strip HTML tags using a regular expression
    return html.replace(/<\/?[^>]+(>|$)/g, '');
};

const PlayTestGameDetailsPage = () => {
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams(); // title_id
    const navigate = useNavigate();

    useEffect(() => {

        const fetchTitle = async () => {
            try {
                const response = await Glitch.api.Titles.view(id);
                setTitle(response.data.data);
            } catch (error) {
                console.error('Error fetching title', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTitle();
    }, [id, navigate]);

    const handleApply = async () => {

        if (!Glitch.util.Session.isLoggedIn() && typeof window !== 'undefined') {

            const redirectUrl = Navigate.playtestingUserGame(id);
            const onboardingUrl = Navigate.creatorsOnboardingStep1Page();
            navigate(`${onboardingUrl}?redirect=${encodeURIComponent(redirectUrl)}`);

        } else {
            try {
                await Glitch.api.PlayTests.requestPlayTest(id);
                Alerts.display('Application Succcess', 'Play test request sent successfully.');
                navigate(Navigate.playtestingUserTests());
            } catch (error) {
                const message = error?.response?.data?.error;
                if (message) {
                    if (message.includes('social media platform')) {
                        Alerts.display(
                            'Social Media Required',
                            'You must connect at least one social media platform before requesting a play test! Please visit your Profile Settings.',
                            'error'
                        );
                    } else {
                        Alerts.display('Application Failed', message);
                    }
                } else {
                    alert('Failed to request play test.');
                }
            }
        }
    };

    const pageTitle = title?.name ? `Play Test: ${title.name}` : 'Play Test';
    const pageDescription = stripHtml(
        title?.short_description || 'This game is looking for play testers. Learn more about it and apply to test!'
    );
    const pageImage = title?.image_main || FALLBACK_IMAGE;
    //const pageUrl = window.location.href; // Or build a canonical URL from your domain

    return (
        <>
            <Helmet prioritizeSeoTags>
                {/* HTML Title */}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph tags */}
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Glitch Gaming    " />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />
            </Helmet>

            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserGames() },
                        { name: title?.name || 'Loading...', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Play Test: {title?.name || 'Loading...'}</h2>
                        <p className="lead">The game below is looking for play testers. Learn more about the game and apply to play test.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : title ? (
                    <>

                        <div className="card mb-4 p-1">
                            <GameTitle gameInfo={title} />

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <button className="btn btn-primary btn-lg mx-2" onClick={handleApply}>
                                    <i className="fas fa-play"></i> Apply to Test
                                </button>
                            </div>

                        </div>


                    </>
                ) : (
                    <p>Game not found.</p>
                )}
            </div>
        </>
    );
};

export default PlayTestGameDetailsPage;
