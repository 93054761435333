import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import { Helmet } from 'react-helmet-async';
import Navigate from '../../../../util/Navigate';

// -----------------------------------
// Setup Stripe
// -----------------------------------
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// -----------------------------------
// All Plan Data (Condensed from Pricing Table)
// -----------------------------------
const plansData = [
  {
    // Plan 1: Solo Dev
    // Be sure to map this to your actual env var if desired
    id: 'SOLO_DEV',
    name: 'Solo Dev Plan',
    price: '$65 / month',
    trial: '14 days',
    activeInfluencers: '2',
    overcharge: '$50 / influencer',
    outreachCredits: '30',
    additionalCredits: '$1.50 / credit',
    socialScheduler: {
      maxPostsPerWeek: 2,
      includes: ['Post to all platforms', 'Analytics on social content']
    },
    communityFeatures: {
      includes: [
        'Community Forums',
        'Gaming Showcases',
        'Host Tournaments',
        'Media Kits',
        'Watermark On Showcases: No',
        'Watermark On MediaKit: No',
        'Videos on MediaKit: Yes'
      ]
    },
    newsletterFeatures: {
      monthlyEmails: '10,000',
      includes: ['Newsletter Sign-Ups']
    },
    playTestersFeatures: {
      invites: '15',
      includes: ['Search Play Testers']
    },
    // Use your actual environment variable for the price ID
    stripePlanId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_INFLUENCER_FLAT
  },
  {
    // Plan 2: Small Team
    id: 'SMALL_TEAM',
    name: 'Small Team Plan',
    price: '$240 / month',
    trial: '14 days',
    activeInfluencers: '10',
    overcharge: '$35 / influencer',
    outreachCredits: '100',
    additionalCredits: '$0.80 / credit',
    socialScheduler: {
      maxPostsPerWeek: 10,
      includes: ['Post to all platforms', 'Analytics on social content']
    },
    communityFeatures: {
      includes: [
        'Community Forums',
        'Gaming Showcases',
        'Host Tournaments',
        'Media Kits',
        'Watermark On Showcases: No',
        'Watermark On MediaKit: No',
        'Videos on MediaKit: Yes'
      ]
    },
    newsletterFeatures: {
      monthlyEmails: '150,000',
      includes: ['Newsletter Sign-Ups']
    },
    playTestersFeatures: {
      invites: '30',
      includes: ['Search Play Testers']
    },
    stripePlanId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_INFLUENCER_PLAN1
  },
  {
    // Plan 3: Studio
    id: 'STUDIO',
    name: 'Studio Plan',
    price: '$450 / month',
    trial: '14 days',
    activeInfluencers: '25',
    overcharge: '$30 / influencer',
    outreachCredits: '200',
    additionalCredits: '$0.60 / credit',
    socialScheduler: {
      maxPostsPerWeek: 20,
      includes: ['Post to all platforms', 'Analytics on social content']
    },
    communityFeatures: {
      includes: [
        'Community Forums',
        'Gaming Showcases',
        'Host Tournaments',
        'Media Kits',
        'Watermark On Showcases: No',
        'Watermark On MediaKit: No',
        'Videos on MediaKit: Yes'
      ]
    },
    newsletterFeatures: {
      monthlyEmails: '300,000',
      includes: ['Newsletter Sign-Ups']
    },
    playTestersFeatures: {
      invites: '45',
      includes: ['Search Play Testers']
    },
    stripePlanId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_INFLUENCER_PLAN2
  },
  {
    // Plan 4: Publisher
    id: 'PUBLISHER',
    name: 'Publisher Plan',
    price: '$840 / month',
    trial: '14 days',
    activeInfluencers: '40',
    overcharge: '$20 / influencer',
    outreachCredits: '350',
    additionalCredits: '$0.50 / credit',
    socialScheduler: {
      maxPostsPerWeek: 30,
      includes: ['Post to all platforms', 'Analytics on social content']
    },
    communityFeatures: {
      includes: [
        'Community Forums',
        'Gaming Showcases',
        'Host Tournaments',
        'Media Kits',
        'Watermark On Showcases: No',
        'Watermark On MediaKit: No',
        'Videos on MediaKit: Yes'
      ]
    },
    newsletterFeatures: {
      monthlyEmails: '500,000',
      includes: ['Newsletter Sign-Ups']
    },
    playTestersFeatures: {
      invites: '60',
      includes: ['Search Play Testers']
    },
    stripePlanId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_INFLUENCER_PLAN3
  },
  {
    // Enterprise Plan (Call or Email)
    id: 'ENTERPRISE',
    name: 'Enterprise Plan',
    price: 'Custom',
    trial: 'Call or Email',
    activeInfluencers: '50+',
    overcharge: 'Custom',
    outreachCredits: 'Custom',
    additionalCredits: 'Custom',
    socialScheduler: {
      maxPostsPerWeek: '-',
      includes: ['Post to all platforms', 'Analytics on social content']
    },
    communityFeatures: {
      includes: [
        'Community Forums',
        'Gaming Showcases',
        'Host Tournaments',
        'Media Kits',
        'Watermark On Showcases: No',
        'Watermark On MediaKit: No',
        'Videos on MediaKit: Yes'
      ]
    },
    newsletterFeatures: {
      monthlyEmails: 'Custom',
      includes: ['Newsletter Sign-Ups']
    },
    playTestersFeatures: {
      invites: '100+',
      includes: ['Search Play Testers']
    },
    stripePlanId: null // "Call or Email" for subscription
  }
];

const PublisherSubscribePage = () => {
  const [community, setCommunity] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPlan) {
      const selectedPlanElement = document.getElementById('selectedPlan');
      if (selectedPlanElement) {
        selectedPlanElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedPlan]);

  useEffect(() => {
    // Fetch Community Info
    Glitch.api.Communities.view(id)
      .then((response) => {
        setCommunity(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // Fetch Current Subscriptions for this Community
    Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(id)
      .then((response) => {
        setCurrentSubscriptions(response.data.data);

        // If user already has an active subscription, find the plan that matches
        const activeSub = response.data.data.find((sub) =>
          plansData.some((plan) => plan.stripePlanId === sub.stripe_price)
        );
        if (activeSub) {
          const matchedPlan = plansData.find(
            (plan) => plan.stripePlanId === activeSub.stripe_price
          );
          setSelectedPlan(matchedPlan);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  // Handle selecting a plan from the UI
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle cancel subscription
  const handleCancel = async (event) => {
    event.preventDefault();

    if (window.confirm("Are you sure you want to cancel your plan? You will not get another free trial and will lose access.")) {

      const activeSubscription = currentSubscriptions.find((sub) =>
        plansData.some((plan) => plan.stripePlanId === sub.stripe_price)
      );

      if (activeSubscription) {
        Glitch.api.Subscriptions.cancelCommunityInfluencerSubscription(
          id,
          activeSubscription.stripe_id
        )
          .then((response) => {
            setSelectedPlan(null);
            setCurrentSubscriptions([]);
            alert('Subscription canceled successfully!');
          })
          .catch((error) => {
            console.error(error);
            alert('Failed to cancel the subscription. If your subscription was cancelled previously and you were on a trial, the trial is still active but your plan is cancelled.');
          });
      }
    }
  };

  // Handle change plan
  const handleChangePlan = async (event, newPriceId) => {
    event.preventDefault();

    const activeSubscription = currentSubscriptions.find((sub) =>
      plansData.some((plan) => plan.stripePlanId === sub.stripe_price)
    );

    if (activeSubscription) {
      Glitch.api.Subscriptions.changeCommunityInfluencerSubscription(id, {
        newPriceId: newPriceId
      })
        .then((response) => {
          // Find the newly selected plan
          const newPlan = plansData.find((plan) => plan.stripePlanId === newPriceId);
          setSelectedPlan(newPlan);
          alert('Subscription changed successfully!');
        })
        .catch((error) => {
          console.error(error);
          alert('Failed to change the subscription. Please try again.');
        });
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Select A Subscription | Glitch Gaming</title>
        <meta name="description" content="Choose from a list of subscriptions." />
      </Helmet>

      <PublisherHeader position="relative" />

      {/* Page Header */}
      <section className="pageheader-section-min">
        <div className="container">
          <div className="section-wrapper text-center">
            <h2 className="pageheader-title">Select A Subscription</h2>
            <p className="lead">
              Choose the best plan for your community and marketing needs.
            </p>
          </div>
        </div>
      </section>

      {/* Plans Section */}
      <section className="py-4">
        <div className="container">
          {/* Horizontal scroll row on smaller devices */}
          <div
            className="row "
            style={{ whiteSpace: 'nowrap' }}
          >
            {plansData.map((plan) => (
              <div
                className="col-12 col-md-4 col-lg-4 d-inline-block"
                style={{ float: 'none' }}
                key={plan.id}
              >
                <PlanCard
                  plan={plan}
                  isSelected={selectedPlan && selectedPlan.id === plan.id}
                  onSelect={handlePlanSelect}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Display payment / subscription details if a paid plan is selected */}
      {selectedPlan && selectedPlan.stripePlanId && (
        <Elements stripe={stripePromise}>
          <SelectedPlanInfo
            plan={selectedPlan}
            communityId={id}
            currentSubscriptions={currentSubscriptions}
            handleCancel={handleCancel}
            handleChangePlan={handleChangePlan}
          />
        </Elements>
      )}

      {/* If Free plan or Enterprise plan is selected */}
      {selectedPlan && !selectedPlan.stripePlanId && (
        <div className="container mb-5">
          <div className="alert alert-info text-center">
            {selectedPlan.id === 'FREE' && (
              <>
                <h4 className='text-black'>You have selected the Free Plan</h4>
                <p>
                  No payment information is required. Enjoy our free features!
                </p>
              </>
            )}
            {selectedPlan.id === 'ENTERPRISE' && (
              <>
                <h4 className='text-black'>You have selected the Enterprise Plan</h4>
                <p>
                  Please schedule a meeting or contact our team to discuss
                  enterprise pricing and features.
                </p>
                <a
                  href="https://calendly.com/ddixon/30min?back=1&month=2024-10"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a Meeting
                </a>
              </>
            )}
          </div>
        </div>
      )}

      {/* Notes / Disclaimers */}
      <div className="container">
        <p>
          <strong>Active Influencers:</strong> The active influencers count
          represents the number of influencers considered active on a campaign
          in a given month, and either are producing content or their past
          content is being tracked. If the number of active influencers exceeds
          the allotted amount, an overcharge fee is incurred for each additional
          influencer.
        </p>
        <p>
          <strong>*Overcharge:</strong> The overcharge is if you work with more
          influencers than allotted in your package. You will be charged the
          overcharge for each additional influencer you choose to work with.
        </p>
        <p>
          <strong>**Outreach Credits:</strong> You can invite influencers to
          your campaign through our database. Each invite will count as one
          credit.
        </p>
        <p>
          <strong>***Additional Credits:</strong> The cost of each additional
          credit to send an invite once your monthly limit is used up.
        </p>
      </div>

      <Footer />

      {/* Basic styling for the card element */}
      <style>{`
        .stripe-card-element {
          border: 1px solid #000;
          padding: 10px;
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};

// -----------------------------------
// PlanCard Component
// -----------------------------------
const PlanCard = ({ plan, isSelected, onSelect }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleMoreInfo = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div
      className="card mb-4"
      style={{
        border: isSelected ? '2px solid #007bff' : '',
        boxShadow: isSelected ? '0 0 10px rgba(0, 123, 255, 0.5)' : ''
      }}
    >
      <div className="card-body d-flex flex-column justify-content-between">
        <h5 className="card-title text-center">{plan.name}</h5>
        <p className="card-text text-center">
          <strong>{plan.price}</strong>
        </p>
        {plan.trial !== 'Call or Email' && (
          <p className="card-text text-center">{plan.trial} free trial</p>
        )}
        {plan.trial === 'Call or Email' && (
          <p className="card-text text-center">Custom Setup</p>
        )}
        <button
          className="btn btn-sm btn-outline-primary w-100 mb-2"
          onClick={handleMoreInfo}
        >
          {showDetails ? 'Hide Info' : 'More Info'}
        </button>

        {showDetails && (
          <div style={{ fontSize: '0.85rem', maxHeight: '300px', overflowY: 'auto' }}>
            <hr />
            <p>
              <strong>Active Influencers: </strong>
              {plan.activeInfluencers}
            </p>
            <p>
              <strong>Overcharge: </strong>
              {plan.overcharge}
            </p>
            <p>
              <strong>Outreach Credits: </strong>
              {plan.outreachCredits}
            </p>
            <p>
              <strong>Additional Credits: </strong>
              {plan.additionalCredits}
            </p>
            <p>
              <strong>Social Scheduler</strong> (Max Posts/Week: {plan.socialScheduler.maxPostsPerWeek})
              <ul>
                {plan.socialScheduler.includes.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </p>
            <p>
              <strong>Community Features</strong>
              <ul>
                {plan.communityFeatures.includes.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </p>
            <p>
              <strong>Newsletter Features</strong> (Monthly Emails: {plan.newsletterFeatures.monthlyEmails})
              <ul>
                {plan.newsletterFeatures.includes.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </p>
            <p>
              <strong>Play Testers Features</strong> (Invites: {plan.playTestersFeatures.invites})
              <ul>
                {plan.playTestersFeatures.includes.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </p>
          </div>
        )}

        <button
          className={`btn btn-sm mt-2 ${isSelected ? 'btn-secondary' : 'btn-primary'
            } w-100`}
          onClick={() => onSelect(plan)}
        >
          {isSelected ? 'Selected' : 'Select'}
        </button>
      </div>
    </div>
  );
};

// -----------------------------------
// SelectedPlanInfo (Payment Form)
// -----------------------------------
const SelectedPlanInfo = ({
  plan,
  communityId,
  currentSubscriptions,
  handleCancel,
  handleChangePlan
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Submit payment
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (stripeError) {
      setError(stripeError.message);
      setLoading(false);
      return;
    }

    Glitch.api.Subscriptions.createCommunityInfluencerSubscription(communityId, {
      priceId: plan.stripePlanId,
      paymentMethod: paymentMethod.id,
      couponCode: couponCode // If you have coupon logic in the backend
    })
      .then(() => {
        setLoading(false);
        alert('Subscription successful!');
        navigate(Navigate.communitiesManagePage(communityId));
      })
      .catch((err) => {
        console.error(err);
        setError('Subscription failed. Please try again.');
        setLoading(false);
      });
  };

  // Check if there's an existing active subscription
  const activeSubscription = currentSubscriptions.find((sub) =>
    sub.stripe_price === plan.stripePlanId
  );

  const cardElementOptions = {
    style: {
      base: {
        color: '#000',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  };

  return (
    <div className="container mb-4">
      <div className="card">
        <div className="card-header text-black">
          <h4 className='text-black' id="selectedPlan">Selected Plan: {plan.name}</h4>
        </div>
        <div className="card-body">
          <p>
            <strong>Price: </strong>
            {plan.price}
          </p>
          <p>
            <strong>Trial: </strong>
            {plan.trial}
          </p>
          <p>
            <strong>Active Influencers: </strong>
            {plan.activeInfluencers}
          </p>
          <p>
            <strong>Overcharge: </strong>
            {plan.overcharge}
          </p>
          <p>
            <strong>Outreach Credits: </strong>
            {plan.outreachCredits}
          </p>
          <p>
            <strong>Additional Credits: </strong>
            {plan.additionalCredits}
          </p>

          {activeSubscription ? (
            <>
              <div className="alert alert-warning">
                You already have an active subscription for this plan. You can
                cancel or change the plan below.
              </div>
              <button
                className="btn btn-danger me-2"
                onClick={handleCancel}
              >
                Cancel Subscription
              </button>
              {plan.stripePlanId !== activeSubscription.stripe_price && (
                <button
                  className="btn btn-primary"
                  onClick={(event) => handleChangePlan(event, plan.stripePlanId)}
                >
                  Change Plan
                </button>
              )}
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div
                className="mb-3 p-2"
                style={{ border: '1px solid #CCC', borderRadius: '4px' }}
              >
                <CardElement options={cardElementOptions} />
              </div>

              <div className="mb-3">
                <label htmlFor="couponCode" className="form-label">
                  Coupon Code (Optional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter your coupon code"
                />
              </div>

              {error && <div className="alert alert-danger">{error}</div>}
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!stripe || loading}
              >
                {loading ? 'Processing...' : 'Subscribe'}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublisherSubscribePage;
