import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faUsers, faChartLine, faRobot, faVideo, faImage } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

const gatherDataImageStyle = {
    width: '100%',
    maxWidth: '500px',    // Adjust to your liking
    height: '200px',      // All images have the same height
    objectFit: 'contain', // or 'cover', depending on desired look
    display: 'block',
    margin: '0 auto'
};


function PublisherSocialSchedulerPage() {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Automate Your Social Media with the Social Scheduler | Glitch Gaming</title>
                <meta
                    name="description"
                    content="Maximize your game's exposure with AI-driven social media management. Save time and increase engagement effortlessly."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Automate Your Social Media with the Social Scheduler | Glitch" />
                <meta property="og:description" content="Maximize your game's exposure with AI-driven social media management. Save time and increase engagement effortlessly." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/social_scheduler_banner.webp" />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 className='mt-5' style={{ fontSize: "2.5rem" }}>Automate Your Social Media</h1>
                <p className="lead"><strong>Save time and increase engagement with our Social Scheduler.</strong></p>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <img
                                src="/assets/images/publishers/top_social_platforms.png"
                                alt="Social Scheduler Demo"
                                className="img-fluid rounded shadow border border-white border-2 mb-3"
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="mb-4">Up To 300 Wishlist a Day - All Organic (Non-paid)</h2>
                            <p className="lead">
                                Games with active social media see higher engagement, more wishlists, and better sales. With the Social Scheduler, you can automate your social strategy effortlessly.
                            </p>
                            <ul className="list-unstyled lead">
                                <li>
                                    <FontAwesomeIcon icon={faRobot} className="text-primary me-2" />
                                    Content creation tailored for each platform
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faVideo} className="text-danger me-2" />
                                    Support for video, text, and image posts
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faChartLine} className="text-success me-2" />
                                    Analytics to track performance
                                </li>
                            </ul>
                            <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Start Automating Social Content</span></Link>


                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-2 text-black">We Automate Social Media Growth</h2>
                    <p className='text-black text-center lead'><u>How It Works</u></p>
                    <div className="row text-center text-black">
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/publishers/scheduler_submit.png" className='img-fluid w-50' />
                            <h4 className='text-black'>Step 1: You Submit Short Updates</h4>
                            <p>Once a week, submit a short piece of content, ie: new feature, fixed bug, an image, etc.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/publishers/scheduler_results.png" className='img-fluid w-50' />
                            <h4 className='text-black'>Step 2: We Create The Content</h4>
                            <p>We turn that content into an engaging post about your game and share it on all social platforms.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/publishers/graph_up.png" className='img-fluid w-50 mt-4' />
                            <h4 className='text-black'>Step 3: Watch Sales/Wishlist Grow!</h4>
                            <p>Watch your wishlist and/or sales grow, as you can track and compare marketing efforts in real-time.</p>
                        </div>

                    </div>
                    <hr className='text-black' />
                    <div className="d-flex justify-content-center align-items-center mb-2" >
                        <h3 className='text-black text-center'>That's It! Your Content Will Go To All The Major Platforms</h3>

                    </div>

                    <div className="col-12 d-flex justify-content-center">
                        <img src={'/assets/images/creators/youtube.png'} alt="YouTube Icon" className="img-fluid mb-2 m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/tiktok.png" alt="TikTok" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/reddit.png" alt="Reddit" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/facebook.png" alt="Facebook" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/twitter.png" alt="Twitter" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/youtube.png" alt="YouTube" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/instagram.png" alt="Instagram" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                    </div>

                </div>
            </section>



            <div className="mb-5 pt-4 text-center bg-light rounded" style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                <div className='container'>
                    <h2 className="mb-4">Analyze How Your Marketing Fuels Growth</h2>
                    <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3 text-success" />
                    <p className="lead">Track each stage in the marketing funnel to see how social awareness drives installs.</p>
                    <img src="/assets/images/publishers/game_funnel.gif" alt="Platform Utilization" className="img-fluid rounded shadow border border-white border-2 " />

                    <form className="contact-form mt-4" id="contact-form" method="POST">
                        <div className="form-group w-100 text-center">
                            <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Sign Up And Use For Free</span></Link>
                        </div>
                    </form>
                </div>
            </div>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-5 text-black">Built for Success</h2>
                    <div className="row text-center text-black">
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faBullhorn} size="3x" className="text-primary mb-3" />
                            <h4 className='text-black'>Consistent Posting</h4>
                            <p>Ensure your social channels stay active and engaging.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faUsers} size="3x" className="text-success mb-3" />
                            <h4 className='text-black'>Audience Growth</h4>
                            <p>Build a loyal community ready to support your game.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faImage} size="3x" className="text-warning mb-3" />
                            <h4 className='text-black'>Creative Freedom</h4>
                            <p>Generate custom content that aligns with your vision.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-dark">
                <div className="container">
                    <h2 className="text-center mb-2 text-white">Gather Deep Data Insights</h2>
                    <p className="text-white text-center">
                        We provide a lot of data on your entire social campaign, allowing you to make data-driven decisions.
                    </p>
                    <div className="row text-center text-black">
                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Statistics</h4>
                            <img
                                src="/assets/images/publishers/chart_statistics.png"
                                alt="Statistics"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Comparisons</h4>
                            <img
                                src="/assets/images/publishers/chart_comparison.png"
                                alt="Comparisons"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Followers</h4>
                            <img
                                src="/assets/images/publishers/chart_follower_growth.png"
                                alt="Follower Growth"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-6 mb-4">
                            <h4 className='text-white'>Progression</h4>
                            <img
                                src="/assets/images/publishers/chart_progression.png"
                                alt="Progression"
                                style={gatherDataImageStyle}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>





            <IndieSubmenu />

            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/social_scheduler_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
};

export default PublisherSocialSchedulerPage;
