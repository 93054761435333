import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

const PublisherGameForumsPage = () => {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Top Forum Solutions for Gaming Communities | Glitch Gaming</title>
                <meta name="description" content="Discover the best open-source forum platforms tailored for gaming communities. Explore tools like MyBB, phpBB, and Glitch Forums to build engagement, boost SEO, and increase player retention." />
                <meta name="keywords" content="gaming community software, open-source forums, MyBB, phpBB, Glitch Forums, Discourse, Vanilla, Invision Community, gaming communities, player engagement, gaming forums" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Top Forum Solutions for Gaming Communities | Glitch" />
                <meta property="og:description" content="Explore top forum software for building and managing thriving gaming communities, including MyBB, phpBB, and Glitch Forums." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/forums/community_forums.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Top Forum Solutions <br /><br />for Gaming Communities</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <h2>Community Forums for Your Game</h2>
                <p className="lead">Community forums are essential for both marketing and user retention. They allow:</p>
                <ol className="lead">
                    <li>Prospective players to research your game before downloading.</li>
                    <li>A space to engage and communicate with players, keeping them updated on your game's progress.</li>
                    <li>Re-using community-generated content for marketing purposes.</li>
                    <li>Public forums to be indexed by search engines, improving SEO visibility.</li>
                </ol>
                <p className="lead">Below are some top community forum solutions to help build vibrant communities around your game:</p>

                {/* Free Forum Solutions Section */}
                <h3 className="mt-5">Free Forum Solutions</h3>

                {/* Glitch Communities Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Glitch Communities</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/glitch.jpg" className='img-fluid rounded' alt="Glitch Communities" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Glitch Forums is designed to empower gaming communities with all the features they need to thrive. Gamers can go live, stream their gameplay, and engage with their fans in real time, while also recording their sessions for future use such as remarketing, tutorials, or Q&A. With robust private messaging and live chat features, players can communicate one-on-one or with groups. The platform also offers tournament and team management tools to boost player engagement, along with customizable revenue streams through ticketing, paywalls, donations, and ads. Glitch Forums is an automated marketing solution to grow your game’s community and increase player engagement.</p>
                            <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-primary btn-lg">
                                Register Here <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* MyBB Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>MyBB</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/myBB.png" className='img-fluid rounded' alt="MyBB" />
                        </div>
                        <div className='col-sm-9'>
                            <p>MyBB is a powerful, open-source forum software ideal for game developers who want to create thriving communities. It offers customizable themes, plugins, and easy-to-use moderation tools, allowing you to build a forum that perfectly complements your game’s atmosphere. MyBB enables players to share experiences, engage in discussions, and provide valuable feedback. By building a dedicated community on MyBB, game developers can foster collaboration, increase player loyalty, and ensure long-term user engagement.</p>
                            <Link to={"https://mybb.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* phpBB Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>phpBB</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/phpBB.webp" className='img-fluid rounded' alt="phpBB" />
                        </div>
                        <div className='col-sm-9'>
                            <p>phpBB is an excellent choice for building structured, interactive gaming communities. With its powerful permission and moderation systems, phpBB allows for customizable forums where players can connect, share game strategies, and offer feedback. Its plugin ecosystem lets developers add extra features like game-specific rankings, announcements, and security enhancements. phpBB provides a reliable and scalable platform for keeping your gaming community engaged and growing over time.</p>
                            <Link to={"https://www.phpbb.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Paid Forum Solutions Section */}
                <h3 className="mt-5">Paid Forum Solutions</h3>

                {/* Discourse Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Discourse</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/discourse.webp" className='img-fluid rounded' alt="Discourse" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Discourse is a modern forum solution that emphasizes user experience and engagement, making it popular for gaming communities. It includes features like real-time notifications, community moderation tools, and customizable themes. Discourse is an open-source solution, but managed hosting options are available for a fee.</p>
                            <Link to={"https://www.discourse.org/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Vanilla Forums Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Vanilla Forums</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/vanilla.png" className='img-fluid rounded' alt="Vanilla Forums" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Vanilla Forums offers a customizable platform suitable for gaming communities of all sizes. It includes a wide range of plugins, themes, and moderation tools to create an engaging space for players. Vanilla has both open-source and paid versions with additional features and support.</p>
                            <Link to={"https://vanillaforums.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Invision Community Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Invision Community</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/invision.png" className='img-fluid rounded' alt="Invision Community" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Invision Community is a paid forum software offering advanced community management features, ideal for larger or more established gaming communities. It includes a powerful suite of tools for moderation, monetization, and user engagement. Invision Community is a premium solution known for its reliability and professional support.</p>
                            <Link to={"https://invisioncommunity.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <IndieSubmenu />
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/gaming_calculator.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.8)',
};

export default PublisherGameForumsPage;
